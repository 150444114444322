import React from 'react';
import '@mantine/core/styles.css';

import { CatalogueGrid } from '../components/CatalogueGrid.js';
import { AuthenticationForm } from '../components/AuthenticationFormCustomer.tsx';
import { Flex, Grid, SimpleGrid } from '@mantine/core';
import { AuthenticationFormCoach } from '../components/AuthenticationFormCoach.tsx';

function LoginCoachPage() {
  return (
      <SimpleGrid 
      cols={{ base: 1, sm: 1, lg: 1 }}  
      style={{margin: 50, justifyContent: 'center', display:'flex' }}
      >
        <AuthenticationFormCoach title="Personal" w={'100%'} margin={'50px'}/>
      </SimpleGrid>
  );
}

export default LoginCoachPage;
