import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Text,
  Button,
  SimpleGrid,
  Loader,
  Group,
  Badge,
  Divider,
  Modal,
  Stack,
  Image,
} from '@mantine/core';
import axios from 'axios';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { showNotification } from '@mantine/notifications';
import { IconArrowLeft, IconCheck, IconX } from '@tabler/icons-react';
import { fetchAndSetUserData } from '../utils/auth';
import utc from 'dayjs/plugin/utc';

// Properly extend dayjs with the UTC plugin
dayjs.extend(utc);

export default function BookVenuePage() {
  const { venueId } = useParams();
  const [venue, setVenue] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    if (!venue) {
      fetchVenueInfo();
    } else {
      fetchScheduleByDate(selectedDate);
    }

    fetchUserId();
  }, [selectedDate]);

  const fetchUserId = async () => {
    await fetchAndSetUserData((userData) => {
      if (userData) {
        setUserId(userData.id); // Set the user ID in the state
      }
    });
  };

  const fetchVenueInfo = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        'https://apidev.sportsnow.app/api/v1/venue/info',
        {
          venue_id: venueId,
          date: selectedDate.format('YYYY-MM-DD'),
        },
        {
          validateStatus: function (status) {
            return status === 200 || status === 404;
          },
        }
      );

      if (response.status === 200) {
        setVenue(response.data.data);
        setSchedules(response.data.data.list_schedule || []);
      } else if (response.status === 404) {
        setSchedules([]);
        setVenue(null);
      }
    } catch (error) {
      console.error("Unexpected error fetching venue info:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchScheduleByDate = async (date) => {
    setLoading(true);
    try {
      const response = await axios.post(
        'https://apidev.sportsnow.app/api/v1/venue/info',
        {
          venue_id: venueId,
          date: date.format('YYYY-MM-DD'),
        },
        {
          validateStatus: function (status) {
            return status === 200 || status === 404;
          },
        }
      );

      if (response.status === 200) {
        setSchedules(response.data.data.list_schedule || []);
      } else if (response.status === 404) {
        setSchedules([]);
      }
    } catch (error) {
      console.error("Unexpected error fetching schedules:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBook = (schedule) => {
    setSelectedSchedule(schedule);
    setModalOpen(true);
  };

  const confirmBooking = async () => {
    setModalOpen(false);
    const bookingPayload = {
      start_time: selectedSchedule.start_hour,
      account_id: userId,
      venue_id: venueId,
      schedule_id: selectedSchedule.id,
      price: venue.price,
      end_time: selectedSchedule.end_hour,
      schedule_type: venue.schedule_type,
    };

    try {
      const response = await axios.post(
        'https://apidev.sportsnow.app/api/v1/auth/booking',
        bookingPayload,
        { withCredentials: true }
      );

      if (response.status === 200) {
        showNotification({
          title: 'Success',
          message: 'Booking successful!',
          color: 'green',
          icon: <IconCheck />,
        });
        fetchScheduleByDate(selectedDate);
      }
    } catch (error) {
      console.error("Booking failed:", error);
      showNotification({
        title: "Failed",
        message: "Booking failed. Please try again.",
        color: "red",
        icon: <IconX />,
      });
    }
  };

  const today = dayjs();
  const nextMonth = today.add(1, 'month');

  return (
    <>
      <Group spacing="xs" onClick={() => navigate(-1)} style={{ maxWidth: 800, margin: 'auto', cursor: 'pointer' }} my={20}>
        <IconArrowLeft size={24} stroke={2}/>
        <Text size="md" fw={500} >
          Back
        </Text>
      </Group>
      <Card withBorder radius="md" padding="xl" style={{ maxWidth: 800, margin: 'auto', backgroundColor: '#1A1B1E' }} my={20}>
        <Group position="apart" noWrap>
          <Text size="xl" weight={700} color="white">
            {venue?.name || "Venue"}
          </Text>
          <Badge
            color={venue?.status === 'available' ? 'green' : 'red'}
            variant="filled"
            size="lg"
            style={{
              fontWeight: 600,
              backgroundColor: venue?.status === 'available' ? 'green' : 'red',
            }}
          >
            {venue?.status === 'available' ? 'Available' : 'Not Available'}
          </Badge>
        </Group>
        
        <Text color="dimmed" size="sm" mt="xs" style={{ textTransform: 'capitalize', letterSpacing: '0.5px' }}>
          {venue?.type ? venue.type.charAt(0).toUpperCase() + venue.type.slice(1) : "Venue Type"}
        </Text>

        <Text color="dimmed" size="sm" style={{ marginTop: '8px' }}>
          {venue?.schedule_type === 'hourly' ? (
            <span style={{ fontWeight: 'bold', color: '#58C8A5' }}>Hourly Booking</span>
          ) : (
            <span style={{ fontWeight: 'bold', color: '#FFA500' }}>Open Session</span>
          )}
        </Text>
        <Card.Section my="md" mx={'xl'}>
          <Image
            src={venue?.profile_picture ? `https://apidev.sportsnow.app/uploads/venue/${venue.profile_picture}` : '/path/to/default-avatar.png'}
            alt={`Venue ${venue?.name}`}
            height={300}
            radius="md" // Adds a slight border radius
            fit="cover" // Ensures the image fits properly within the dimensions
          />
        </Card.Section>
        <Divider my="md" />

        <Text size="lg" weight={600} mb="sm">Select Date</Text>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(dayjs(newValue))}
            minDate={today}
            maxDate={nextMonth}
            sx={{
              '& .MuiTypography-root': { color: 'white' },
              '& .Mui-selected': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
              '& .MuiButtonBase-root': { color: 'white' },
            }}
          />
        </LocalizationProvider>

        <Divider my="md" />

        <Text size="lg" weight={600} mt="md">Available Schedules</Text>
        {loading ? (
          <Loader mt="sm">Loading schedules...</Loader>
        ) : schedules.length > 0 ? (
          <SimpleGrid cols={3} spacing="md" mt="md">
            {schedules.map((schedule) => (
              <Card key={schedule.id} withBorder radius="md" padding="sm" style={{ textAlign: 'center' }}>
                <Text weight={500}>
                  {dayjs(schedule.start_hour).utc().format('HH:mm')} - {dayjs(schedule.end_hour).utc().format('HH:mm')}
                </Text>
                <Text size="sm" color="dimmed" mt="xs">
                  Price: {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(venue.price)}{venue.schedule_type === 'hourly' ? "/hour" : "/minute"}
                </Text>
                <Button
                  fullWidth
                  mt="sm"
                  color={schedule.status === 'booked' ? 'gray' : 'blue'}
                  onClick={() => handleBook(schedule)}
                  disabled={schedule.status === 'booked'}
                >
                  {schedule.status === 'booked' ? 'Booked' : 'Book'}
                </Button>
              </Card>
            ))}
          </SimpleGrid>
        ) : (
          <Text align="center" mt="md">No schedules available for this date.</Text>
        )}
      </Card>

      {/* Confirmation Modal */}
      <Modal opened={modalOpen} onClose={() => setModalOpen(false)} title="Confirm Booking">
        <Stack spacing="md">
          <Text>Are you sure you want to book this time slot?</Text>
          <Text>
            <strong>Time:</strong> {selectedSchedule && dayjs(selectedSchedule.start_hour).utc().format('HH:mm')} - {selectedSchedule && dayjs(selectedSchedule.end_hour).utc().format('HH:mm')}
          </Text>
          <Text>
            <strong>Price:</strong> {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(venue?.price)} {venue?.schedule_type === 'hourly' ? "/hour" : "/minute"}

          </Text>
          <Group position="apart">
            <Button color="red" onClick={() => setModalOpen(false)}>Cancel</Button>
            <Button color="green" onClick={confirmBooking}>Confirm</Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
}
