import {jwtDecode} from 'jwt-decode';


export const getDecodedPayload = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
};


export const getAuthTokenFromCookies = () => {
  const cookies = document.cookie.split('; ');
  const authCookie = cookies.find((cookie) => cookie.startsWith('Authorization='));

  if (!authCookie) {
    return null;
  }

  return authCookie.split('=')[1];
};

export const getUserRoleFromToken = (token) => {
  const decodedPayload = getDecodedPayload(token);

  if (!decodedPayload || !decodedPayload.role) {
    throw new Error('Role not found in the token');
  }

  return decodedPayload.role;
};