import React, { useState, useEffect } from 'react';
import { CoachCatalogueGrid } from '../components/CoachCatalogueGrid';
import axios from 'axios';

function FindCoachPage() {
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCoaches = async () => {
      setLoading(true);
      try {
        const response = await axios.post('https://apidev.sportsnow.app/api/v1/coaches', { page: 1 });
        if (response.data && response.data.code === 200) {
          setCoaches(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching coaches:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCoaches();
  }, []);

  return (
    <CoachCatalogueGrid 
      title={"Coaches"} 
      items={coaches}
      loading={loading}
    />
  );
}

export default FindCoachPage;
