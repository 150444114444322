import React, { useEffect, useState } from 'react';
import { Container, Image, Card, Text, SimpleGrid, Loader, Group, Divider, Center, Modal, Button, TextInput, Select, NumberInput, Stack, FileInput, ThemeIcon, Flex, rem } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { useForm } from '@mantine/form';
import axios from 'axios';
import { getDecodedPayload, getAuthTokenFromCookies } from '../utils/jwt';
import { IconAlertCircle, IconCheck, IconClock, IconShoppingCart, IconX } from '@tabler/icons-react';
import { Hidden } from '@mui/material';
import { VenueCard } from '../components/VenueCard';
import { showNotification } from '@mantine/notifications';

function BusinessHomePage() {
  const [banners, setBanners] = useState([]);
  const [scStatus, setScStatus] = useState(null);
  const [venueStatus, setVenueStatus] = useState([]);
  const [scheduleType, setScheduleType] = useState('');
  const [loadingBanners, setLoadingBanners] = useState(true);
  const [loadingScStatus, setLoadingScStatus] = useState(true);
  const [loadingVenueStatus, setLoadingVenueStatus] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staffModalOpen, setStaffModalOpen] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;

  useEffect(() => {
    setLoadingBanners(true);
    setLoadingScStatus(true);
    setLoadingVenueStatus(true);

    const token = getAuthTokenFromCookies();

    if (!token) {
      setError('User is not authenticated.');
      return;
    }

    const decodedPayload = getDecodedPayload(token);
    if (!decodedPayload || !decodedPayload.sub) {
      setError('Invalid token or user ID missing');
      return;
    }

    const businessId = decodedPayload.sub;

    const fetchBanners = async () => {
      try {
        const response = await axios.post('https://apidev.sportsnow.app/api/v1/business/banner', {}, {
          withCredentials: true,
        });
        if (response.data && response.data.code === 200) {
          setBanners(response.data.data);
        } else {
          throw new Error(response.data.message || 'Failed to fetch banner data');
        }
      } catch (err) {
        console.error('Error fetching banners:', err);
        setError('Failed to fetch banners');
      } finally {
        setLoadingBanners(false);
      }
    };

    const fetchScStatus = async () => {
      try {
        const response = await axios.post('https://apidev.sportsnow.app/api/v1/auth/business/status', { business_id: businessId }, {
          withCredentials: true,
        });
        if (response.data && response.data.code === 200) {
          setScStatus(response.data.data);
        } else {
          throw new Error(response.data.message || 'Failed to fetch sport center status');
        }
      } catch (err) {
        console.error('Error fetching sport center status:', err);
        setError('Failed to fetch sport center status');
      } finally {
        setLoadingScStatus(false);
      }
    };

    const fetchVenueStatus = async () => {
      try {
        const response = await axios.post('https://apidev.sportsnow.app/api/v1/auth/business/venue/status', { id: businessId }, {
          withCredentials: true,
        });
        if (response.data && response.data.code === 200) {
          setVenueStatus(response.data.data);
        } else {
          throw new Error(response.data.message || 'Failed to fetch venue status');
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.code === 404) {
          setError('Venue not found');
        } else {
          setError(err.message || 'An unexpected error occurred');
        }
        console.error('Error fetching venue status:', err);
      } finally {
        setLoadingVenueStatus(false);
      }
    };

    const fetchStaffList = async () => {
      try {
        const response = await axios.get('https://apidev.sportsnow.app/api/v1/auth/business/staff/list', {
          withCredentials: true,
        });
        if (response.data && response.data.code === 200) {
          setStaffList(response.data.data);
        }
      } catch (err) {
        console.error('Error fetching staff list:', err);
      }
    };

    fetchBanners();
    fetchScStatus();
    fetchVenueStatus();
    // fetchStaffList();
  }, []);

  // Venue form configuration
  const form = useForm({
    initialValues: {
      business_account_id: '',
      name: '',
      profile_picture: null,
      status: '',
      type: '',
      price_per_min: null,
      price_per_session: null,
      schedule_type: '',
      start_hour: '',
      end_hour: '',
    },
    validate: {
      name: (value) => (value ? null : 'Name is required'),
      status: (value) => (value ? null : 'Status is required'),
      type: (value) => (value ? null : 'Type is required'),
      price_per_min: (value) => (value ? null : 'Price per Minute is required'),
      price_per_session: (value) => (value ? null : 'Price per Session is required'),
      profile_picture: (value) => (value ? null : 'Venue Picture is required'),
      schedule_type: (value) => (value ? null : 'Schedule Type is required'),
    },
  });

  const staffForm = useForm({
    initialValues: {
      name: '',
      position: '',
    },
  });

  useEffect(() => {
    const token = getAuthTokenFromCookies();
    const decodedPayload = getDecodedPayload(token);
    const businessId = decodedPayload.sub;
    form.setFieldValue('business_account_id', businessId);
  }, []);

  const handleVenueUpdate = (updatedVenue) => {
    setVenueStatus((prevStatus) =>
      prevStatus.map((venue) =>
        venue.id === updatedVenue.id ? updatedVenue : venue
      )
    );
  };

  const handleVenueDelete = (deletedVenueId) => {
    setVenueStatus((prevStatus) => prevStatus.filter(venue => venue.id !== deletedVenueId));
  };

  const handleSubmit = async (values) => {
    console.log("Form submitted with values:", values);
    setLoading(true);

    let profilePictureURL = '';
    if (values.profile_picture) {
      const formData = new FormData();
      formData.append('profilePicture', values.profile_picture);

      try {
        const uploadResponse = await axios.post(
          'https://apidev.sportsnow.app/api/v1/auth/upload/venue/image',
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
          }
        );
        profilePictureURL = uploadResponse.data.url;
      } catch (error) {
        showNotification({
          title: "Failed",
          message: 'Failed to upload file',
          color: "red",
          icon: xIcon,
        });
        setLoading(false);
        return;
      }
    }
    try {
      const response = await axios.post(
        'https://apidev.sportsnow.app/api/v1/auth/business/venue/add',
        {
          ...values,
          profile_picture: profilePictureURL,
        },
        { withCredentials: true }
      );

      if (response.data && response.data.code === 200) {
        const newVenue = response.data.data; // Assuming the new venue data is in response.data.data
        
        setVenueStatus((prevStatus) => [...prevStatus, newVenue]);
        setModalOpen(false);
        form.reset();
        showNotification({
          title: 'Success',
          message: 'Venue added successfully!',
          color: 'green',
          icon: checkIcon,
        });
      } else {
        throw new Error(response.data.message || 'Failed to add venue');
      }
    } catch (err) {
      console.error('Error adding venue:', err);
      showNotification({
        title: 'Failed',
        message: 'Failed to add venue',
        color: 'red',
        icon: xIcon,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleStaffSubmit = async (values) => {
    try {
      const token = getAuthTokenFromCookies();
      const decodedPayload = getDecodedPayload(token);
      const businessId = decodedPayload.sub;
      const response = await axios.post('https://apidev.sportsnow.app/api/v1/auth/business/staff/add', {
        ...values,
        business_account_id: businessId,
      }, {
        withCredentials: true,
      });

      if (response.data && response.data.code === 200) {
        setStaffList((prevStaffList) => [...prevStaffList, response.data.data]);
        setStaffModalOpen(false);
        staffForm.reset();
        showNotification({
          title: 'Success',
          message: 'Staff added successfully!',
          color: 'green',
          icon: checkIcon,
        });
      } else {
        throw new Error(response.data.message || 'Failed to add staff');
      }
    } catch (err) {
      console.error('Error adding staff:', err);
      showNotification({
        title: 'Failed',
        message: 'Failed to add staff',
        color: 'red',
        icon: xIcon,
      });
    }
  };

  return (
    <>
      {/* Banner Carousel */}
      {loadingBanners && <Loader>Loading banners...</Loader>}
      {!loadingBanners && !error && (
        <Carousel
          withIndicators
          height={300}
          slideSize="50%"
          slideGap="md"
          align="start"
          loop
          breakpoints={[
            { maxWidth: 'md', slideSize: '50%' },
            { maxWidth: 'sm', slideSize: '100%' },
          ]}
        >
          {banners.map((banner) => (
            <Carousel.Slide key={banner.id}>
              <a href={banner.redirect_url} target="_blank" rel="noopener noreferrer">
                <Image
                  src={`https://apidev.sportsnow.app/uploads/${banner.path_image}`}
                  alt="Banner"
                  height={300}
                  fit="cover"
                />
              </a>
            </Carousel.Slide>
          ))}
        </Carousel>
      )}

      <Container size="lg" py="xl">
        {/* Add Venue Button and Modal */}
        <Button onClick={() => setModalOpen(true)} mb="xl" mr={'xl'}>
          Add Venue
        </Button>
        <Modal opened={modalOpen} onClose={() => setModalOpen(false)} title="Add Venue">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack spacing="md">
              <TextInput
                label="Sports Center ID"
                placeholder="Enter Sports Center ID"
                {...form.getInputProps('business_account_id')}
                style={{ display: 'none' }}
              />
              <TextInput
                label="Venue Name"
                type="string"
                placeholder="Enter venue name"
                {...form.getInputProps('name')}
              />
              <FileInput
                label="Venue Picture"
                placeholder="Upload your venue picture"
                accept="image/*"
                value={form.values.profile_picture}
                onChange={(file: File | null) => {
                  if (!file) {
                    form.setFieldError('profile_picture', 'Profile picture is required');
                    return;
                  }
                  if (file && file.size > 2.5 * 1024 * 1024) { // 2.5MB limit
                    form.setFieldError('profile_picture', 'File size should not exceed 2.5MB');
                    return;
                  }
                  form.setFieldError('profile_picture', null); // Clear error if file is valid
                  form.setFieldValue('profile_picture', file);
                }}
                error={form.errors.profile_picture} // Show error below the input field
                radius="md"
              />
              <Select
                label="Status"
                placeholder="Select status"
                data={[
                  { value: 'available', label: 'Available' },
                  { value: 'not available', label: 'Not Available' },
                  { value: 'booked', label: 'Booked' },
                  { value: 'maintenance', label: 'Maintenance' },
                ]}
                {...form.getInputProps('status')}
              />
              <Select
                label="Type"
                placeholder="Select type"
                data={[
                  { value: 'basic', label: 'Basic' },
                  { value: 'premium', label: 'Premium' },
                  { value: 'vip', label: 'VIP' },
                ]}
                {...form.getInputProps('type')}
              />
              <NumberInput
                label="Price per Minute"
                placeholder="Enter price per minute"
                {...form.getInputProps('price_per_min')}
              />
              <NumberInput
                label="Price per Session"
                placeholder="Enter price per session"
                {...form.getInputProps('price_per_session')}
              />
              <Select
                label="Schedule Type"
                placeholder="Select schedule type"
                data={[
                  { value: 'hourly', label: 'Hourly' },
                  { value: 'open', label: 'Open' },
                ]}
                {...form.getInputProps('schedule_type')}
                onChange={(value) => {
                  form.setFieldValue('schedule_type', value);
                  setScheduleType(value);
                }}
              />
              {/* Show StartHour and EndHour if Schedule Type is 'session' */}
              {/* {scheduleType === 'hourly' && (
                <>
                  <TextInput
                    label="Start Hour"
                    placeholder="Enter start hour (HH:MM)"
                    {...form.getInputProps('start_hour')}
                  />
                  <TextInput
                    label="End Hour"
                    placeholder="Enter end hour (HH:MM)"
                    {...form.getInputProps('end_hour')}
                  />
                </>
              )} */}
              <Button type="submit" fullWidth loading={loading}>
                Submit
              </Button>
            </Stack>
          </form>
        </Modal>

        {/* Add Staff Button and Modal */}
        <Button onClick={() => setStaffModalOpen(true)} mb="xl">
          Add Staff
        </Button>
        <Modal opened={staffModalOpen} onClose={() => setStaffModalOpen(false)} title="Add Staff">
          <form onSubmit={staffForm.onSubmit(handleStaffSubmit)}>
            <Stack spacing="md">
              <TextInput
                label="Staff Name"
                placeholder="Enter staff name"
                {...staffForm.getInputProps('name')}
                required
              />
              <TextInput
                label="Position"
                placeholder="Enter staff position"
                {...staffForm.getInputProps('position')}
                required
              />
              <Button type="submit" fullWidth loading={loading}>
                Submit
              </Button>
            </Stack>
          </form>
        </Modal>

        {/* Sport Center Status */}
        {loadingScStatus ? (
          <Loader>Loading sport center status...</Loader>
        ) : (
          <Card withBorder radius="md" shadow="md" padding="xl" mb="xl">
            <Text size="xl" weight={700} align="start" mb="md">
              Sport Center Status
            </Text>
            <Divider mb="lg" />
            <Group position="apart" spacing="xl" grow>
              <Group direction="column" align="center" spacing="xs">
                <ThemeIcon variant="light" color={scStatus?.is_open ? 'green' : 'red'} radius="xl" size="xl">
                  <IconAlertCircle size={24} />
                </ThemeIcon>
                <Text size="sm" weight={500}>Status</Text>
                <Text size="md" color={scStatus?.is_open ? 'green' : 'red'}>
                  {scStatus?.is_open ? 'Open' : 'Closed'}
                </Text>
              </Group>
              
              <Group direction="column" align="center" spacing="xs">
                <ThemeIcon variant="light" color="blue" radius="xl" size="xl">
                  <IconShoppingCart size={24} />
                </ThemeIcon>
                <Text size="sm" weight={500}>Today’s Transactions</Text>
                <Text size="md" color="blue">
                  {scStatus?.today_transaction || 0}
                </Text>
              </Group>

              <Group direction="column" align="center" spacing="xs">
                <ThemeIcon variant="light" color="orange" radius="xl" size="xl">
                  <IconClock size={24} />
                </ThemeIcon>
                <Text size="sm" weight={500}>Pending Transactions</Text>
                <Text size="md" color="orange">
                  {scStatus?.undone_transaction || 0}
                </Text>
              </Group>

              <Group direction="column" align="center" spacing="xs">
                <ThemeIcon variant="light" color="teal" radius="xl" size="xl">
                  <IconCheck size={24} />
                </ThemeIcon>
                <Text size="sm" weight={500}>Completed Transactions</Text>
                <Text size="md" color="teal">
                  {scStatus?.done_transaction || 0}
                </Text>
              </Group>
            </Group>
          </Card>
        )}

        {/* Venue Status */}
        {loadingVenueStatus ? (
          <Loader>Loading venue status...</Loader>
        ) : (
          <Card withBorder radius="md" padding="xl">
            <Text size="xl" weight={500} mb="sm">Venue Status</Text>
            <Divider mb="sm" />
            {venueStatus.length === 0 ? (
              <Text align="center" mt={20}>No venue found</Text>
            ) : (
              <SimpleGrid cols={3} spacing="lg">
                {venueStatus.map((venue) => (
                  <VenueCard
                    id={venue.id}
                    name={venue.name}
                    type={venue.type}
                    status={venue.status}
                    profilePicture={venue.profile_picture}
                    pricePerMin={venue.price_per_min}
                    pricePerSession={venue.price_per_session}
                    onVenueUpdate={handleVenueUpdate}
                    onVenueDelete={handleVenueDelete}
                  />
                ))}
              </SimpleGrid>
            )}
          </Card>
        )}
      </Container>
    </>
  );
}

export default BusinessHomePage;