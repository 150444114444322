import { useToggle, upperFirst } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import {
  TextInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Divider,
  Stack,
  PinInput,
  rem,
} from '@mantine/core';
import { GoogleButton } from '../assets/GoogleButton.tsx';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconCheck, IconX } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';

interface AuthenticationFormCustomerProps extends PaperProps {
  title: string;
}

export function AuthenticationFormCustomer({ title, ...props }: AuthenticationFormCustomerProps) {
  const [type, toggle] = useToggle(['login', 'register']);
  const [isOtpVisible, setIsOtpVisible] = useState(false); 
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(''); 
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const navigate = useNavigate();

  // Icons for Notifications
  const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;

  // Login form validation
  const loginForm = useForm({
    initialValues: {
      email: '',
      name: '',
      password: '',
      terms: true,
    },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
    },
  });

  // OTP form validation
  const otpForm = useForm({
    initialValues: {
      otp: '',
    },
    validate: {
      otp: (val) => (/^\d{6}$/.test(val) ? null : 'OTP must be exactly 6 digits'),
    },
  });

  // Handle form submission for the login form
  const handleLoginSubmit = async (values: typeof loginForm.values) => {
    if (!loginForm.errors.email) {
      setEmail(values.email);
      setIsLoading(true);
      try {
        const response = await axios.post('https://apidev.sportsnow.app/api/v1/sign-in/request', {
          email: values.email,
        }, {
          withCredentials : true,
        });        

        if (response.status === 200) {
          showNotification({
            title: 'Success',
            message: 'OTP sent successfully, please check your email',
            color: 'green',
            icon: checkIcon,
          });
          setIsOtpVisible(true); 
        }
      } catch (error) {
        showNotification({
          title: 'Error',
          message: 'Failed to send OTP. Please try again.',
          color: 'red',
          icon: xIcon,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Handle OTP submission
  const handleOtpSubmit = async (values: typeof otpForm.values) => {
    // Validate OTP form before proceeding
    const validationErrors = otpForm.validate();
    if (validationErrors.otp) {
      // Display validation error as notification
      showNotification({
        title: 'Invalid OTP',
        message: validationErrors.otp,
        color: 'red',
        icon: xIcon,
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post('https://apidev.sportsnow.app/api/v1/sign-in/verify', {
        email,
        otp: values.otp,
      }, {
        withCredentials: true, // Ensure that cookies are sent with the request
      });

      if (response.status === 200) {
        setIsOtpVerified(true);

        // Perform profile check
        try {
          const profileCheckResponse = await axios.post('https://apidev.sportsnow.app/api/v1/auth/sign-in/check', {
            email,
          }, {
            withCredentials: true, // Ensure that cookies are sent with the request
          });

          if (profileCheckResponse.status === 200) {
            navigate('/profile');
            window.location.reload();
          }
        } catch (error) {
          // Handle specific error cases
          if (error.response && error.response.status === 404) {
            navigate('/register/customer', { state: { email } });
          } else {
            showNotification({
              title: 'Error',
              message: 'Unexpected error occurred. Please try again.',
              color: 'red',
              icon: xIcon,
            });
          }
        }
      } else {
        showNotification({
          title: 'Invalid OTP',
          message: 'The OTP you entered is incorrect.',
          color: 'red',
          icon: xIcon,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        showNotification({
          color: 'red',
          title: 'Invalid OTP',
          message: 'The OTP you inputted is invalid.',
          icon: xIcon,
        });
      } else {
        showNotification({
          title: 'Error',
          message: 'An unexpected error occurred. Please try again.',
          color: 'red',
          icon: xIcon,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {!isOtpVisible ? (
        // Login Form
        <Paper
          radius="md"
          p="xl"
          w={'100%'}
          h={'100%'}
          style={title === 'Business' ? { background: '#1871c3' } : {}}
          withBorder={title !== 'Business'}
          {...props}
        >
          <Text fz={30} fw={500}>
            Customer Account
          </Text>
          <Text size="lg" fw={500}>
            Welcome to Sports Now, {upperFirst(type)} with
          </Text>

          <Group grow mb="md" mt="md">
            <GoogleButton radius="xl" disabled>Google</GoogleButton>
          </Group>

          <Divider label="Or continue with email" labelPosition="center" my="lg" />

          <form onSubmit={loginForm.onSubmit(handleLoginSubmit)}>
            <Stack>
              <TextInput
                label="Email"
                placeholder="hello@sportsnow.app" 
                {...loginForm.getInputProps('email')}
                radius="md"
              />
            </Stack>

            <Group justify="space-between" mt="xl">
              <Button type="submit" radius="xl" loading={isLoading}>
                {isLoading ? 'Loading...' : upperFirst(type)}
              </Button>
            </Group>
          </form>
        </Paper>
      ) : (
        // OTP Form
        <Paper
          radius="md"
          p="xl"
          w={'100%'}
          style={title === 'Business' ? { background: '#1871c3' } : {}}
          withBorder={title !== 'Business'}
          {...props}
        >
          <Text fz={30} fw={500}>
            Personal Account
          </Text>
          <Text size="lg" fw={500}>
            We've sent you the OTP
          </Text>

          <Divider label="Please check your email" labelPosition="center" my="lg" />

          <form onSubmit={otpForm.onSubmit(handleOtpSubmit)}>
            <Stack>
              <Text size="md" fw={500}>
                OTP
              </Text>
              <PinInput 
                length={6} 
                {...otpForm.getInputProps('otp')}
                error={otpForm.errors.otp && otpForm.errors.otp}
              />
            </Stack>

            <Group justify="space-between" mt="xl">
              <Button type="submit" radius="xl" loading={isLoading}>
                {isLoading ? 'Verifying...' : 'Verify OTP'}
              </Button>
            </Group>
          </form>
        </Paper>
      )}
    </div>
  );
}
