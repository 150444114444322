import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SimpleGrid, Text, Card, Button, Group } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import axios from 'axios';
import { VenueFeaturesCard } from '../components/VenueFeaturesCard';

export default function TablesPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { centerId, centerName } = location.state || {};
  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (centerId) {
      const fetchVenues = async () => {
        setLoading(true);
        try {
          const response = await axios.post('https://apidev.sportsnow.app/api/v1/venues', { ID: centerId });
          if (response.data && response.data.code === 200) {
            setVenues(response.data.data);
          }
        } catch (error) {
          console.error("Error fetching venues:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchVenues();
    }
  }, [centerId]);

  useEffect(() => {
    if (!centerName) {
      // Optionally fallback to localStorage or another method
      console.warn('Center Name is missing');
    }
  }, [centerName]);
  

  return (
    <Card withBorder radius="md" mb={50} padding={50}>
      <Group spacing="xs" style={{ cursor: 'pointer' }} onClick={() => navigate('/sportcenters')}>
        <IconArrowLeft size={24} stroke={2}/>
        <Text size="md" fw={500} >
          Back
        </Text>
      </Group>
      <Text fz="xl" fw={600} mt={20}>{centerName}</Text>
      {loading ? (
        <Text align="center" mt="md">Loading...</Text>
      ) : venues.length > 0 ? (
        <SimpleGrid cols={{ base: 1, sm: 3, lg: 4 }} mt="md">
          {venues.map((venue) => (
            <VenueFeaturesCard
              key={venue.id}
              id={venue.id}
              name={venue.name}
              status={venue.status}
              type={venue.type}
              scheduleType={venue.schedule_type}
              price={venue.price}
              profilePicture={venue.profile_picture}
            />
          ))}
        </SimpleGrid>
      ) : (
        <Text align="center" mt="md">No venues available</Text>
      )}
    </Card>
  );
}
