import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Group,
  Button,
  UnstyledButton,
  Text,
  ThemeIcon,
  Divider,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  rem,
  useMantineTheme,
  Menu,
  Avatar,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconNotification, IconCode, IconBook, IconFingerprint, IconCoin, IconChartPie3, IconChevronDown, IconLogout } from '@tabler/icons-react';
import { isAuthenticated, logout } from '../utils/auth';
import SportsnowLogo from '../assets/sportsnow-white.png';
import classes from '../styles/HeaderMegaMenu.module.css';
import { useUser } from '../context/UserContext';

const mockdata = [
  { icon: IconCode, title: 'Open source', description: 'This Pokémon’s cry is very loud and distracting' },
  { icon: IconCoin, title: 'Free for everyone', description: 'The fluid of Smeargle’s tail secretions changes' },
  { icon: IconBook, title: 'Documentation', description: 'Yanma is capable of seeing 360 degrees without' },
  { icon: IconFingerprint, title: 'Security', description: 'The shell’s rounded shape and the grooves on its.' },
  { icon: IconChartPie3, title: 'Analytics', description: 'This Pokémon uses its flying ability to quickly chase' },
  { icon: IconNotification, title: 'Notifications', description: 'Combusken battles with the intensely hot flames it spews' },
];

export function HeaderMegaMenu() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const theme = useMantineTheme();
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    setUser(null);
    navigate('/');
  };

  const links = mockdata.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.title}>
      <Group wrap="nowrap" align="flex-start">
        <ThemeIcon size={34} variant="default" radius="md">
          <item.icon style={{ width: rem(22), height: rem(22) }} color={theme.colors.blue[6]} />
        </ThemeIcon>
        <div>
          <Text size="sm" fw={500}>
            {item.title}
          </Text>
          <Text size="xs" c="dimmed">
            {item.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));

  return (
    <Box pb={0}>
      <header className={classes.header}>
        <Group justify="space-between" h="100%" style={{ borderBottom: '1px solid #eaeaea' }}>
        <Link to="/">
          <img src={SportsnowLogo} alt="My Logo" style={{ width: '50px', height: 'auto' }} />
        </Link>

          <Group h="100%" gap={0} visibleFrom="sm" ml={70}>
          {user ? (
              <Link to="/home" className={classes.link}>Home</Link>
          ) : null}

              <>
                <Link to="/coaches" className={classes.link}>Find Coach</Link>
                <Link to="/sportcenters" className={classes.link}>Sport Centers</Link>
                <Link to="/contactus" className={classes.link}>Contact Us</Link>
              </>
          </Group>


          <Group visibleFrom="sm">
            {user ? (
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <UnstyledButton className={classes.userButton}>
                    <Group gap={7}>
                      <Text fw={500} size="sm">{user.name}</Text>
                      <IconChevronDown style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                    </Group>
                  </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item onClick={() => navigate('/profile')}>Profile</Menu.Item>
                  <Menu.Item color="red" icon={<IconLogout size={16} />} onClick={handleLogout}>
                    Logout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ) : (
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <Box>
                    <Button>Log in</Button>
                  </Box>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item>
                    <Link to="/login/customer" className={classes.link}>As Customer</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/login/business" className={classes.link}>As Business Owner</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/login/coach" className={classes.link}>As Coach</Link>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </Group>

          <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
        </Group>
      </header>

      <Drawer
  opened={drawerOpened}
  onClose={closeDrawer}
  size="100%"
  padding="md"
  title="Navigation"
  hiddenFrom="sm"
  zIndex={1000000}
>
  <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
    <Divider my="sm" />

    {user ? (
      <Link to="/home" className={classes.link} onClick={closeDrawer}>
        Home
      </Link>
    ) : null}
    <>
      <Link to="/coaches" className={classes.link} onClick={closeDrawer}>
        Find Coach
      </Link>
      <Link to="/sportcenters" className={classes.link} onClick={closeDrawer}>
        Sport Centers
      </Link>
      <Link to="/contactus" className={classes.link} onClick={closeDrawer}>
        Contact Us
      </Link>
    </>

    <Divider my="sm" />

    {user ? (
      <Button color="red" onClick={() => { handleLogout(); closeDrawer(); }} mx="-md">
        Logout
      </Button>
    ) : (
      <>
        <Link to="/login/customer" className={classes.link} onClick={closeDrawer}>
          Log in as Customer
        </Link>
        <Link to="/login/business" className={classes.link} onClick={closeDrawer}>
          Log in as Business Owner
        </Link>
        <Link to="/login/coach" className={classes.link} onClick={closeDrawer}>
          Log in as Coach
        </Link>
      </>
    )}
  </ScrollArea>
</Drawer>

    </Box>
  );
}
