import axios from 'axios';
import { getAuthTokenFromCookies, getDecodedPayload } from './jwt';
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from '@tabler/icons-react';
import { rem } from '@mantine/core';

const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;

export const isAuthenticated = () => {
  const cookies = document.cookie.split('; ');
  const authCookie = cookies.find(cookie => cookie.startsWith('Authorization='));
  
  if (!authCookie) return false; 
  
  const token = authCookie.split('=')[1]; 
  
  try {
    const decodedPayload = getDecodedPayload(token); 
    if (!decodedPayload || !decodedPayload.exp) return false;
    
    const currentTime = Math.floor(Date.now() / 1000);
    return decodedPayload.exp > currentTime; 
  } catch (error) {
    console.error('Failed to validate JWT:', error);
    return false; 
  }
};

export const logout = async () => {
  try {
    await axios.post('https://apidev.sportsnow.app/api/v1/logout', {}, { withCredentials: true });
    document.cookie = 'Authorization=; Max-Age=0; path=/;';
    window.location.href = '/';
  } catch (error) {
    console.error('Failed to log out:', error);
    alert('Logout failed. Please try again.');
  }
};

export const fetchAndSetUserData = async (setUserData, setUser, setError, navigate) => {
  let userType;
  try {
    const token = getAuthTokenFromCookies();
    if (!token) throw new Error('User is not authenticated.');

    const decodedPayload = getDecodedPayload(token);
    if (!decodedPayload || !decodedPayload.sub) throw new Error('Invalid token or user ID missing');

    const userId = decodedPayload.sub;
    userType = decodedPayload.role;

    let endpoint = '';
    if (userType === 'customer') {
      endpoint = 'https://apidev.sportsnow.app/api/v1/auth/account';
    } else if (userType === 'business') {
      endpoint = 'https://apidev.sportsnow.app/api/v1/auth/business/detail';
    } else if (userType === 'coach') {
      endpoint = 'https://apidev.sportsnow.app/api/v1/auth/coach/detail';
    } else {
      throw new Error('Invalid user type');
    }

    const response = await axios.post(endpoint, { id: userId }, { withCredentials: true });

    if (response.data && response.data.code === 200) {
      const userData = response.data.data;
      if (setUserData) setUserData(userData);
      if (setUser) setUser({ name: userData.name, type: userType, userId });
    } else {
      showNotification({
        title: "Error",
        message: "Session has expired. Please login again.",
        color: "red",
        icon: xIcon,
      });
      logout();
      window.location.href = `/login/${userType}`;
      throw new Error(response.data.message || 'Failed to fetch user data');
    }
  } catch (error) {
    console.log(error);
    if (error.message === "Invalid token or user ID missing") {
      showNotification({
        title: "Error",
        message: "Session has expired. Please login again.",
        color: "red",
        icon: xIcon,
      });
      // window.location.reload();
      // logout();
    }
    if (setError) setError(error.message || 'Failed to fetch user data');
  }
};
