import React from 'react';
import '@mantine/core/styles.css';

import { CatalogueGrid } from '../components/CatalogueGrid';

function SportCentersPage() {
  return (
      <CatalogueGrid title={"Sport Centers"} />
  );
}

export default SportCentersPage;
