import {
    Badge,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    useMantineTheme,
    Flex,
  } from '@mantine/core';
  import { IconGauge, IconUser, IconCookie, IconTable, IconClock, IconDashboard, IconBrandComedyCentral, IconTarget } from '@tabler/icons-react';
  import classes from '../styles/FeaturesCards.module.css';
  
  const mockdata = [
    {
      title: 'Flexible Booking',
      description:
        'Users can book sports sessions without rigid hourly constraints.',
      icon: IconClock,
    },
    {
      title: 'Convenience First',
      description:
        'Order and play seamlessly, using real-time schedule availability.',
      icon: IconUser,
    },
    {
      title: 'Centralized Platform',
      description:
        'One-stop solution for online booking and discovering coaches.',
      icon: IconTarget,
    },
  ];
  
  export function FeaturesCards({children}) {
    const theme = useMantineTheme();
    const features = mockdata.map((feature) => (
      <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl" style={{alignItems:'center', textAlign:'center'}}>
        <feature.icon
          style={{ width: rem(50), height: rem(50) }}
          stroke={2}
          color={theme.colors.blue[6]}
        />
        <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
          {feature.title}
        </Text>
        <Text fz="sm" c="dimmed" mt="sm">
          {feature.description}
        </Text>
      </Card>
    ));
  
    return (
      <Container size="lg" py="xl">
        <Group justify="center">
          <Badge variant="filled" size="lg">
            Sports Now
          </Badge>
        </Group>
  
        <Title order={2} className={classes.title} ta="center" mt="sm">
        Empowering Sports Enthusiasts
        </Title>
  
        <Text c="dimmed" className={classes.description} ta="center" mt="md">
        Sports Now is your go-to platform for seamless sports booking and play, starting with one potential sports category, then start expand to other sports category. We offer flexible booking options, real-time status availability, and easy access to certified and skilled coaches —all in one convenient hub.

        </Text>
  
        <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={50} mb={50} >
          {features}
        </SimpleGrid>

        {children}
      </Container>
    );
  }