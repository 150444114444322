import { useForm } from '@mantine/form';
import {
  TextInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Stack,
  NumberInput,
  FileInput,
} from '@mantine/core';
import axios from 'axios';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface RegistrationFormProps extends PaperProps {
  title: string;
}

export function RegistrationForm({ title, ...props }: RegistrationFormProps) {
  const navigate = useNavigate();
  const location = useLocation(); 
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    initialValues: {
      email: location.state?.email || '', 
      name: '',
      phoneNumber: '',
      profilePicture: null as File | null, 
    },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
      phoneNumber: (val) => (val ? null : 'Phone number is required'),
      name: (val) => (val ? null : 'Name is required'),
    },
  });

  // Handles the profile registration form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    if (!form.validate().hasErrors) {
      const { profilePicture } = form.values;
      let profilePictureURL = '';
      if (!form.values.profilePicture) {
        form.setFieldError('profilePicture', 'Profile picture is required');
        return;
      }

      if (form.values.profilePicture.size > 2.5 * 1024 * 1024) {
        form.setFieldError('profilePicture', 'File size should not exceed 2.5MB');
        return;
      }      

      if (profilePicture) {
        const formData = new FormData();
        formData.append('profilePicture', profilePicture);

        try {
          setIsLoading(true);
          const response = await axios.post('https://apidev.sportsnow.app/api/v1/auth/upload/account/image', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          });
          profilePictureURL = response.data.url;
        } catch (error) {
          console.error('Failed to upload file', error);
          alert('File upload failed');
          setIsLoading(false);
          return;
        }
      }
      
      // Register the user with the form data and the uploaded image URL
      try {
        const response = await axios.post(
          'https://apidev.sportsnow.app/api/v1/auth/sign-up/submit',
          {
            email: form.values.email,
            name: form.values.name,
            phone_number: form.values.phoneNumber.toString().replace(/[^0-9]/g, ''),
            profile_picture: profilePictureURL,
          },
          {
            withCredentials: true,
          }
        );
        navigate('/profile');
        window.location.reload();
      } catch (error) {
        console.error('Registration failed:', error);
        alert('Registration failed. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div
      style={{
        flex: '1 1 55%',
        maxWidth: '600px',
        width: '100%',
      }}
    >
      <Paper
        radius="md"
        p="xl"
        w={'100%'}
        h={'100%'}
        style={title === 'Business' ? { background: '#1871c3' } : {}}
        withBorder={title !== 'Business'}
        {...props}
      >
        <div style={{ textAlign: 'center' }}>
          <Text fz={30} fw={500} mb={30}>
            Register Account
          </Text>
        </div>

        <form onSubmit={handleSubmit}>
          <Stack>
            <TextInput
              label="Email"
              placeholder="hello@sportsnow.app"
              value={form.values.email}
              onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
              error={form.errors.email && 'Invalid email'}
              radius="md"
              disabled
            />
            <TextInput
              label="Name"
              placeholder="Enter your name"
              value={form.values.name}
              onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
              error={form.errors.name && 'Name is required'}
              radius="md"
            />
            <NumberInput
              label="Phone Number"
              placeholder="Enter your phone number"
              value={form.values.phoneNumber}
              onChange={(value) => form.setFieldValue('phoneNumber', value || '')}
              error={form.errors.phoneNumber && 'Phone number is required'}
              radius="md"
              hideControls
            />
            <FileInput
              label="Profile Picture"
              placeholder="Upload your profile picture"
              accept="image/*"
              value={form.values.profilePicture}
              onChange={(file: File | null) => {
                if (!file) {
                  form.setFieldError('profilePicture', 'Profile picture is required');
                  return;
                }
                if (file && file.size > 2.5 * 1024 * 1024) { // 2.5MB limit
                  form.setFieldError('profilePicture', 'File size should not exceed 2.5MB');
                  return;
                }
                form.setFieldError('profilePicture', null); // Clear error if file is valid
                form.setFieldValue('profilePicture', file);
              }}
              error={form.errors.profilePicture} // Show error below the input field
              radius="md"
            />


          </Stack>

          <Group justify="space-between" mt="xl">
            <Button type="submit" radius="xl" loading={isLoading}>
              {isLoading ? 'Loading...' : 'Register'}
            </Button>
          </Group>
        </form>
      </Paper>
    </div>
  );
}
