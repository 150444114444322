import {
  Card,
  Text,
  SimpleGrid,
  Anchor,
  Group,
  useMantineTheme
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { BusinessFeaturesCard } from './BusinessFeaturesCard';
import axios from 'axios';
import classes from '../styles/ActionsGrid.module.css';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export function BusinessActionsGrid({ title }) {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post('https://apidev.sportsnow.app/api/v1/sports-center', { page: 1 });
        if (response.data && response.data.code === 200) {
          setData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const limitedItems = data.slice(0, 3);

  const handleBookClick = (centerId, centerName) => {
    navigate(`/tables`, { state: { centerId, centerName} });
  };


  const businessItems = limitedItems.map((item) => (
    <BusinessFeaturesCard
      key={item.id}
      title={item.name}
      email={item.email}
      phoneNumber={item.phone_number}
      address={item.address}
      openHour={item.open_hour}
      closeHour={item.close_hour}
      description={item.description}
      longitude={item.longitude}
      latitude={item.latitude}
      isOpen={item.is_open}
      profilePicture={item.profile_picture}
      onBook={() => handleBookClick(item.id, item.name)}
    />
  ));

  return (
    <Card withBorder radius="md" className={classes.card} mb={50}>
      <Group justify="space-between">
        <Text className={classes.title}>{title}</Text>
        <Anchor size="xs" c="dimmed" style={{ lineHeight: 1 }} href={`/${title.toLowerCase().replace(/\s+/g, '')}`}>
          + {data.length} other {title.toLowerCase()}
        </Anchor>
        
      </Group>
      {loading ? (
        <Text align="center" mt="md">Loading...</Text>
      ) : (
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }} mt="md">
          {businessItems}
        </SimpleGrid>
      )}
    </Card>
  );
}
