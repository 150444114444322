import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Text,
  Group,
  Badge,
  SimpleGrid,
  Button,
  Loader,
  Divider,
  Modal,
  Stack,
  Image,
} from '@mantine/core';
import axios from 'axios';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { getAuthTokenFromCookies, getDecodedPayload } from '../utils/jwt';
import { showNotification } from '@mantine/notifications';
import { IconArrowLeft, IconCheck, IconX } from '@tabler/icons-react';
import utc from 'dayjs/plugin/utc';
import { Avatar } from '@mui/material';

dayjs.extend(utc);

function CoachDetailPage() {
  const { coachId } = useParams();
  const [coach, setCoach] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Default is today
  const [loadingCoach, setLoadingCoach] = useState(true);
  const [loadingSchedule, setLoadingSchedule] = useState(true);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    // Fetch user ID from token
    const token = getAuthTokenFromCookies();
    if (!token) {
      showNotification({
        title: "Authentication Error",
        message: "You must be logged in to book a coach.",
        color: "red",
        icon: <IconX />,
      });
      return;
    }

    const decodedPayload = getDecodedPayload(token);
    setUserId(decodedPayload.sub);

    // Fetch Coach Details on initial load
    fetchCoachDetails();
  }, []); // Empty dependency array to ensure this runs only once when the component mounts

  useEffect(() => {
    // Fetch schedules whenever the selected date changes
    if (coach) {
      fetchScheduleByDate(selectedDate);
    }
  }, [coach, selectedDate]); // Re-run when coach is set or selectedDate changes

  const fetchCoachDetails = async () => {
    setLoadingCoach(true);
    setLoadingSchedule(true); // Also show schedule loading indicator initially
    try {
      const response = await axios.post(
        'https://apidev.sportsnow.app/api/v1/coach/info',
        {
          coach_id: coachId,
          date: selectedDate.format('YYYY-MM-DD'), // Include the selected date
        },
        {
          validateStatus: function (status) {
            return status === 200 || status === 404;
          },
        }
      );

      if (response.status === 200) {
        setCoach(response.data.data);
        setSchedules(response.data.data.schedule || []);
      } else if (response.status === 404) {
        setSchedules([]);
        setCoach(null);
      }
    } catch (error) {
      console.error('Error fetching coach details:', error);
    } finally {
      setLoadingCoach(false);
      setLoadingSchedule(false);
    }
  };

  const fetchScheduleByDate = async (date) => {
    setLoadingSchedule(true);
    try {
      const response = await axios.post(
        'https://apidev.sportsnow.app/api/v1/coach/info',
        {
          coach_id: coachId,
          date: date.format('YYYY-MM-DD'),
        },
        {
          validateStatus: function (status) {
            return status === 200 || status === 404;
          },
        }
      );

      if (response.status === 200) {
        setSchedules(response.data.data.schedule || []);
      } else if (response.status === 404) {
        setSchedules([]);
      }
    } catch (error) {
      console.error('Unexpected error fetching schedules:', error);
    } finally {
      setLoadingSchedule(false);
    }
  };

  const handleBook = (schedule) => {
    setSelectedSchedule(schedule);
    setModalOpen(true);
  };

  const confirmBooking = async () => {
    setModalOpen(false);

    if (!selectedSchedule || !userId) return;

    const bookingPayload = {
      account_id: userId,
      coach_schedule_id: selectedSchedule.id,
    };

    try {
      const response = await axios.post(
        'https://apidev.sportsnow.app/api/v1/auth/coach/booking',
        bookingPayload,
        { withCredentials: true }
      );

      if (response.data && response.data.code === 200) {
        showNotification({
          title: 'Booking Success',
          message: 'Coach successfully booked!',
          color: 'green',
          icon: <IconCheck />,
        });

        // Update the schedule to reflect the new booking status
        setSchedules((prevSchedules) =>
          prevSchedules.map((schedule) =>
            schedule.id === selectedSchedule.id ? { ...schedule, status: 'booked' } : schedule
          )
        );
      } else {
        showNotification({
          title: 'Booking Failed',
          message: response.data.message,
          color: 'red',
          icon: <IconX />,
        });
      }
    } catch (error) {
      console.error('Error booking coach:', error);
      showNotification({
        title: 'Booking Error',
        message: 'An error occurred while booking the coach.',
        color: 'red',
        icon: <IconX />,
      });
    }
  };

  const today = dayjs();
  const nextMonth = today.add(1, 'month');

  return (
    <>
      <Group spacing="xs" onClick={() => navigate(-1)} style={{ maxWidth: 800, margin: 'auto', cursor: 'pointer' }} my={20}>
        <IconArrowLeft size={24} stroke={2}/>
        <Text size="md" fw={500} >
          Back
        </Text>
      </Group>
      <Card withBorder radius="md" padding="xl" style={{ maxWidth: 800, margin: 'auto', backgroundColor: '#1A1B1E' }} my={20}>
        <Card
            radius="md"
            padding="xl"
            style={{
              maxWidth: 800,
              margin: 'auto',
              backgroundColor: '#1A1B1E',
              textAlign: 'center',
              alignItems : 'center',
            }}
            
            my={20}
          >

              {coach?.profile_picture ? (
                <Avatar
                  src={`https://apidev.sportsnow.app/uploads/coach/${coach.profile_picture}`}
                  alt={coach?.name || 'Coach Profile'}
                  style={{
                    width: 200,
                    height: 200, 
                  }}
                />
              ) : (
                <Avatar
                  style={{
                    width: 200,
                    height: 200, 
                    fontSize: 50, 
                    backgroundColor: 'blue', 
                    color: 'white',
                  }}
                >
                  {coach?.name ? coach.name.charAt(0) : 'C'}
                </Avatar>
              )}

              <Text size="xl" weight={700} color="white" mt="md">
                {coach?.name || 'Coach'}
              </Text>
              <Text
                color="dimmed"
                size="sm"
                mt="xs"
                style={{ textTransform: 'capitalize', letterSpacing: '0.5px' }}
              >
                {coach?.description || 'Coach Description'}
              </Text>
          </Card>

        <Divider my="md" />

        <Text size="lg" weight={600} mb="sm">Select Date for Schedule</Text>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(dayjs(newValue))}
            minDate={today}
            maxDate={nextMonth}
            sx={{
              '& .MuiTypography-root': { color: 'white' },
              '& .Mui-selected': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
              '& .MuiButtonBase-root': { color: 'white' },
            }}
          />
        </LocalizationProvider>

        <Divider my="md" />

        <Text size="lg" weight={600} mt="md">Available Schedules</Text>
        {loadingSchedule ? (
          <Loader mt="sm">Loading schedules...</Loader>
        ) : schedules.length > 0 ? (
          <SimpleGrid cols={3} spacing="md" mt="md">
          {schedules.map((schedule) => (
            <Card key={schedule.id} withBorder radius="md" padding="sm" style={{ textAlign: 'center' }}>
              <Text weight={500}>
                {dayjs(schedule.start_time).utc().format('HH:mm')} - {dayjs(schedule.end_time).utc().format('HH:mm')}
              </Text>
              <Text size="sm" color="dimmed" mt="xs">
                Price: {schedule.price} IDR
              </Text>
              <Button
                fullWidth
                mt="sm"
                color={schedule.status === 'available' ? 'blue' : 'gray'}
                onClick={() => handleBook(schedule)}
                disabled={schedule.status !== 'available'}
              >
                {schedule.status === 'available' ? 'Book Now' : 'Booked'}
              </Button>
            </Card>
          ))}
        </SimpleGrid>

        ) : (
          <Text align="center" mt="md">No schedules available for this date.</Text>
        )}
      </Card>

      {/* Booking Confirmation Modal */}
      <Modal opened={modalOpen} onClose={() => setModalOpen(false)} title="Confirm Booking">
        <Stack spacing="md">
          <Text>Are you sure you want to book this coach?</Text>
          <Text>
            <strong>Time:</strong> {selectedSchedule && dayjs(selectedSchedule.start_time).utc().format('HH:mm')} - {selectedSchedule && dayjs(selectedSchedule.end_time).utc().format('HH:mm')}
          </Text>
          <Text>
            <strong>Price:</strong> {selectedSchedule?.price} IDR
          </Text>
          <Group position="apart">
            <Button color="red" onClick={() => setModalOpen(false)}>Cancel</Button>
            <Button color="green" onClick={confirmBooking}>Confirm Booking</Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
}

export default CoachDetailPage;
