import { useForm } from '@mantine/form';
import {
  TextInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Stack,
  NumberInput,
  FileInput,
  Textarea,
} from '@mantine/core';
import axios from 'axios';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface RegistrationFormCoachProps extends PaperProps {
  title: string;
}

export function RegistrationFormCoach({ title, ...props }: RegistrationFormCoachProps) {
  const location = useLocation(); // Access the location to get the state
  const [isLoading, setIsLoading] = useState(false);
  const [profilePictureURL, setProfilePictureURL] = useState(''); 
  const navigate = useNavigate();


  // Registration form validation with email pre-filled from OTP verification
  const form = useForm({
    initialValues: {
      email: location.state?.email || '', // Pre-fill email from state, or default to empty
      name: '',
      description: '',
      phoneNumber: '',
      profilePicture: null as File | null, // Specify type File | null
    },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
      phoneNumber: (val) => (val ? null : 'Phone number is required'),
      name: (val) => (val ? null : 'Name is required'),
      profilePicture: (val) => (val ? null : 'Profile picture is required'),
      description: (val) => {
        if (val.length < 10) {
          return 'Description must be at least 10 characters';
        } else if (val.length > 100) {
          return 'Description must be less than 100 characters';
        }
        return null;
      },
    },
  });

  // Handles the profile registration form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!form.validate().hasErrors) {
      setIsLoading(true);

      try {
        // Upload Profile Picture First
        const { profilePicture } = form.values;
        if (!profilePicture) {
          alert('Please select a profile picture first!');
          return;
        }
        if (!form.values.profilePicture) {
          form.setFieldError('profilePicture', 'Profile picture is required');
          return;
        }
  
        if (form.values.profilePicture.size > 2.5 * 1024 * 1024) {
          form.setFieldError('profilePicture', 'File size should not exceed 2.5MB');
          return;
        }      

        const formData = new FormData();
        formData.append('profilePicture', profilePicture);

        const uploadResponse = await axios.post(
          'https://apidev.sportsnow.app/api/v1/auth/upload/coach/image',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          }
        );

        // Set the URL for the profile picture after uploading it
        const uploadedImageUrl = uploadResponse.data.url;
        setProfilePictureURL(uploadedImageUrl);

        // Proceed with Coach Registration
        const registrationResponse = await axios.post(
          'https://apidev.sportsnow.app/api/v1/auth/coach/sign-up/submit',
          {
            email: form.values.email,
            name: form.values.name,
            phone_number: form.values.phoneNumber.toString().replace(/[^0-9]/g, ''),
            profile_picture: uploadedImageUrl,
            description: form.values.description,
          },
          {
            withCredentials: true,
          }
        );

        console.log(registrationResponse.status);
        navigate('/profile');
        window.location.reload();

      } catch (error) {
        console.error('Registration failed:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div
      style={{
        flex: '1 1 55%',
        maxWidth: '600px',
        width: '100%',
      }}
    >
      <Paper
        radius="md"
        p="xl"
        w={'100%'}
        h={'100%'}
        style={title === 'Business' ? { background: '#1871c3' } : {}}
        withBorder={title !== 'Business'}
        {...props}
      >
        <div style={{ textAlign: 'center' }}>
          <Text fz={30} fw={500} mb={30}>
            Register Coach Account
          </Text>
        </div>

        <form onSubmit={handleSubmit}>
          <Stack>
            <TextInput
              label="Email"
              placeholder="hello@sportsnow.app"
              value={form.values.email}
              onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
              error={form.errors.email && 'Invalid email'}
              radius="md"
              disabled // Disable the email input since it's pre-filled
            />
            <TextInput
              label="Name"
              placeholder="Enter your name"
              value={form.values.name}
              onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
              error={form.errors.name && 'Name is required'}
              radius="md"
            />
            <NumberInput
              label="Phone Number"
              placeholder="Enter your phone number"
              value={form.values.phoneNumber}
              onChange={(value) => form.setFieldValue('phoneNumber', value || '')}
              error={form.errors.phoneNumber && 'Phone number is required'}
              radius="md"
              hideControls // Disable the button for increase and decrease
            />
            <FileInput
              label="Profile Picture"
              placeholder="Upload your profile picture"
              accept="image/*"
              value={form.values.profilePicture}
              onChange={(file: File | null) => form.setFieldValue('profilePicture', file)}
              error={form.errors.profilePicture && 'Profile picture is required'}
              radius="md"
            />
            <Textarea
              label="Description"
              placeholder="Enter a brief description of yourself"
              value={form.values.description}
              onChange={(event) => form.setFieldValue('description', event.currentTarget.value)}
              error={form.errors.description}
              radius="md"
            />
          </Stack>

          <Group justify="space-between" mt="xl">
            <Button type="submit" radius="xl" loading={isLoading}>
              {isLoading ? 'Loading...' : 'Register'}
            </Button>
          </Group>
        </form>
      </Paper>
    </div>
  );
}
