import React, { useEffect, useState } from 'react';
import {
  Card,
  TextInput,
  Text,
  Group,
  Loader,
  Divider,
  Textarea,
  NumberInput,
  Avatar,
  Button,
  Modal,
  Stack,
  FileInput,
} from '@mantine/core';
import { useUser } from '../context/UserContext';
import { fetchAndSetUserData } from '../utils/auth';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import axios from 'axios';

const ProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userType, setUserType] = useState('');
  const [userLoaded, setUserLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { user, setUser } = useUser();

  const loadData = async () => {
    setLoading(true);
    setError(null);
    await fetchAndSetUserData(setUserData, setUser, setError);
    setLoading(false);
    setUserLoaded(true);
  };

  useEffect(() => {
    loadData();
  }, [setUser]);

  useEffect(() => {
    if (userLoaded && user && user.type) {
      setUserType(user.type);
    }
  }, [user, userLoaded]);

  const form = useForm({
    initialValues: {
      name: '',
      phone_number: '',
      address: '',
      description: '',
      open_hour: '',
      close_hour: '',
      longitude: 0,
      latitude: 0,
      profile_picture: null,
    },
    validate: {
      profile_picture: (file) => {
        if (!file) return 'Profile picture is required';
        if (file.size > 2.5 * 1024 * 1024) return 'File size should not exceed 2.5MB';
        return null;
      },
    },
  });

  useEffect(() => {
    if (userData) {
      const openHour = userData.open_hour
        ? new Date(userData.open_hour).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })
        : '';
      const closeHour = userData.close_hour
        ? new Date(userData.close_hour).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })
        : '';

      form.setValues({
        id: userData.id,
        name: userData.name || '',
        phone_number: userData.phone_number || '',
        address: userData.address || '',
        description: userData.description || '',
        open_hour: openHour,
        close_hour: closeHour,
        longitude: userData.longitude || 0,
        latitude: userData.latitude || 0,
        profile_picture: null, // Reset file input
      });
    }
  }, [userData]);

  const handleSubmit = async (values) => {
    try {
      const openHour = `1970-01-01T${values.open_hour}:00Z`;
      const closeHour = `1970-01-01T${values.close_hour}:00Z`;

      let endpoint = '';
      if (userType === 'customer') {
        endpoint = '/account/update';
      } else if (userType === 'business') {
        endpoint = '/business/update';
      } else if (userType === 'coach') {
        endpoint = '/coach/update';
      }

      // Handle file upload
      let profilePictureURL = '';
      if (values.profile_picture) {
        const formData = new FormData();
        formData.append('profilePicture', values.profile_picture);

        // Upload the image first
        const uploadResponse = await axios.post(
          `https://apidev.sportsnow.app/api/v1/auth/account/profile-picture/update`,
          {
            id: userData.id, // Pass user ID to associate the image
            profile_picture: values.profile_picture.name, // Assume backend processes file name correctly
          },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        );

        profilePictureURL = uploadResponse.data.url; // Update with backend's response
      }

      // Submit the updated profile data
      await axios.post(
        `https://apidev.sportsnow.app/api/v1/auth${endpoint}`,
        {
          ...values,
          open_hour: openHour,
          close_hour: closeHour,
          profile_picture: profilePictureURL,
        },
        {
          withCredentials: true,
        }
      );

      showNotification({
        title: 'Success',
        message: 'Profile updated successfully!',
        color: 'green',
        icon: <IconCheck />,
      });
      setModalOpen(false);
      loadData();
    } catch (error) {
      console.error('Error updating profile:', error);
      showNotification({
        title: 'Failed to Update Profile',
        message: 'Please try again.',
        color: 'red',
        icon: <IconX />,
      });
    }
  };

  if (loading) return <Loader style={{ textAlign: 'center' }}>Loading...</Loader>;
  if (error)
    return (
      <Text color="red">
        {error} <Button variant="outline" onClick={loadData}>Retry</Button>
      </Text>
    );

  return (
    <>
      <Card shadow="sm" padding="lg" radius="md" style={{ maxWidth: 600, margin: 'auto' }} mt={30} mb={30}>
        <Text size="lg" weight={500} align="center" mb="md">
          Profile Information
        </Text>

        {userData && userType && (
          <>
            <Group position="center" my="md" justify="center">
              <Avatar
                src={userData.profile_picture ? `https://apidev.sportsnow.app/uploads/customer/${userData.profile_picture}` : '/path/to/default-avatar.png'}
                size={200}
                variant="filled"
                alt="Profile"
              />
            </Group>
            <Text size="xl" weight={500} align="center" mb="md">
              {userData.name}
            </Text>
            <Divider my="md" />
            <TextInput label="Email" value={userData?.email || ''} readOnly radius="md" mb="md" />
            <TextInput label="Name" value={userData?.name || ''} readOnly radius="md" mb="md" />
            <NumberInput label="Phone Number" value={userData?.phone_number || ''} readOnly radius="md" mb="md" />
            {userType === 'business' && (
              <>
                <TextInput label="Address" value={userData?.address || ''} readOnly radius="md" mb="md" />
                <Textarea label="Description" value={userData?.description || ''} readOnly radius="md" mb="md" />
                <TextInput label="Open Hour" value={form.values.open_hour} readOnly radius="md" mb="md" />
                <TextInput label="Close Hour" value={form.values.close_hour} readOnly radius="md" mb="md" />
                <NumberInput label="Longitude" value={userData?.longitude || 0} readOnly radius="md" mb="md" />
                <NumberInput label="Latitude" value={userData?.latitude || 0} readOnly radius="md" mb="md" />
              </>
            )}
            {userType === 'coach' && (
              <Textarea label="Description" value={userData?.description || ''} readOnly radius="md" mb="md" />
            )}
          </>
        )}
        <Button fullWidth onClick={() => setModalOpen(true)} mt="md">
          Update Profile
        </Button>
      </Card>

      <Modal opened={modalOpen} onClose={() => setModalOpen(false)} title="Update Profile">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack spacing="md">
            <TextInput label="Name" placeholder="Enter your name" {...form.getInputProps('name')} required />
            <TextInput label="Phone Number" placeholder="Enter your phone number" {...form.getInputProps('phone_number')} required />
            {userType === 'business' && (
              <>
                <TextInput label="Address" placeholder="Enter your address" {...form.getInputProps('address')} />
                <Textarea label="Description" placeholder="Enter description" {...form.getInputProps('description')} />
                <TextInput label="Open Hour" placeholder="Enter open hour" {...form.getInputProps('open_hour')} />
                <TextInput label="Close Hour" placeholder="Enter close hour" {...form.getInputProps('close_hour')} />
                <NumberInput label="Longitude" placeholder="Enter longitude" {...form.getInputProps('longitude')} />
                <NumberInput label="Latitude" placeholder="Enter latitude" {...form.getInputProps('latitude')} />
              </>
            )}
            {userType === 'coach' && (
              <Textarea label="Description" placeholder="Enter description" {...form.getInputProps('description')} />
            )}
            <FileInput
              label="Profile Picture"
              placeholder="Upload your profile picture"
              accept="image/*"
              {...form.getInputProps('profile_picture')}
            />
            <Button type="submit" fullWidth loading={loading} mt="md">
              Update Profile
            </Button>
          </Stack>
        </form>
      </Modal>
    </>
  );
};

export default ProfilePage;
