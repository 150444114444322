import React, { useState } from 'react';
import { Card, Image, Text, Group, Badge, Center, Button, Modal, TextInput, NumberInput, Select, Stack, rem } from '@mantine/core';
import { IconMapDollar, IconUsers } from '@tabler/icons-react';
import axios from 'axios';
import classes from '../styles/FeaturesCard.module.css'; // Use the same CSS file for consistent styling
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';

export function VenueCard({ id, name, type, status, profilePicture, pricePerMin, pricePerSession, onVenueUpdate, onVenueDelete }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;

  const [formValues, setFormValues] = useState({
    name,
    type,
    status,
    profilePicture,
    pricePerMin,
    pricePerSession,
  });

  const openEditModal = () => {
    setIsModalOpen(true);
  };

  const closeEditModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (field, value) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await axios.post(
        `https://apidev.sportsnow.app/api/v1/auth/business/venue/update`,
        {
          id: id,
          name: formValues.name,
          type: formValues.type,
          status: formValues.status,
          price_per_min: formValues.pricePerMin,
          price_per_session: formValues.pricePerSession,
        },
        {
          withCredentials: true,
        }
      );
      showNotification({
        title: 'Success',
        message: 'Venue updated successfully!',
        color: 'green',
        icon: checkIcon,
      });

      onVenueUpdate({
        id,
        name: formValues.name,
        type: formValues.type,
        status: formValues.status,
        profilePicture: formValues.profilePicture,
        pricePerMin: formValues.pricePerMin,
        pricePerSession: formValues.pricePerSession,
      });

      closeEditModal();
    } catch (error) {
      console.error('Error updating venue:', error);
      showNotification({
        title: "Failed to Update Venue",
        message: "Please try again.",
        color: "red",
        icon: xIcon,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await axios.post(`https://apidev.sportsnow.app/api/v1/auth/business/venue/delete`,         
        {
        id: id,
      },
      {
        withCredentials: true,
      });
      showNotification({
        title: 'Deleted',
        message: 'Venue deleted successfully!',
        color: 'green',
        icon: checkIcon,
      });

      onVenueDelete(id);
    } catch (error) {
      console.error('Error deleting venue:', error);
      showNotification({
        title: "Failed to Delete Venue",
        message: "Please try again.",
        color: "red",
        icon: xIcon,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card withBorder radius="md" className={classes.card}>
        <Card.Section className={classes.imageSection}>
          <Image src={`https://apidev.sportsnow.app/uploads/venue/${profilePicture}`} alt={`Venue ${name}`} />
        </Card.Section>

        <Group justify="space-between" mt="md">
            <Text fw={500}>{name}</Text>
          <div>
            <Badge color={status === 'available' ? 'green' : 'red'}>{status === 'available' ? 'Available' : 'Not Available'}</Badge>
          </div>
        </Group>

        <Card.Section className={classes.section} mt="md">
          <Group gap={8} mb={-8}>
            <Center>
              <IconUsers size="1.05rem" className={classes.icon} stroke={1.5} color="gray" />
              <Text size="xs" ml={5}>{type}</Text>
            </Center>
          </Group>
        </Card.Section>

        <Card.Section className={classes.section}>
          <Group justify="space-between">
            {/* <Group align="center" gap={8}>
              <IconMapDollar size="1rem" className={classes.icon} stroke={1.5} color="green" />
              <Text fz="md" fw={500} color="green">${pricePerMin}/min</Text>
            </Group>
            <Group align="center" gap={8}>
              <IconMapDollar size="1rem" className={classes.icon} stroke={1.5} color="blue" />
              <Text fz="md" fw={500} color="blue">${pricePerSession}/session</Text>
            </Group> */}
            <Button radius="xl" onClick={openEditModal} style={{ flex: 0.5 }}>
              Edit
            </Button>
            <Button radius="xl" color="red" onClick={handleDelete} style={{ flex: 0.5 }} loading={loading}>
              Delete
            </Button>
          </Group>
        </Card.Section>
      </Card>

      <Modal opened={isModalOpen} onClose={closeEditModal} title={`Edit ${name}`}>
        <Stack spacing="md">
          <TextInput
            label="Venue Name"
            value={formValues.name}
            onChange={(value) => handleInputChange('name', value)}
            required
          />
          <Select
            label="Type"
            value={formValues.type}
            onChange={(value) => handleInputChange('type', value)}
            data={[
              { value: 'basic', label: 'Basic' },
              { value: 'premium', label: 'Premium' },
              { value: 'vip', label: 'VIP' },
            ]}
            required
          />
          <Select
            label="Status"
            value={formValues.status}
            onChange={(value) => handleInputChange('status', value)}
            data={[
              { value: 'available', label: 'Available' },
              { value: 'not available', label: 'Not Available' },
              { value: 'booked', label: 'Booked' },
              { value: 'maintenance', label: 'Maintenance' },
            ]}
            required
          />
          <NumberInput
            label="Price per Minute"
            value={formValues.pricePerMin}
            onChange={(value) => handleInputChange('pricePerMin', value)}
            min={0}
            required
          />
          <NumberInput
            label="Price per Session"
            value={formValues.pricePerSession}
            onChange={(value) => handleInputChange('pricePerSession', value)}
            min={0}
            required
          />
          <Button fullWidth onClick={handleSubmit} loading={loading}>
            Submit
          </Button>
        </Stack>
      </Modal>
    </>
  );
}
