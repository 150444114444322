import React from 'react';
import '@mantine/core/styles.css';

import CoachHomePage from './CoachHomePage.js';
import BusinessHomePage from './BusinessHomePage.tsx';
import CustomerHomePage from './CustomerHomePage.js';
import { getAuthTokenFromCookies, getUserRoleFromToken } from '../utils/jwt.js';

function HomePage() {
  const token = getAuthTokenFromCookies();
  const userType = getUserRoleFromToken(token);

  if (userType === 'customer') {
    return <CustomerHomePage />;
  } else if (userType === 'coach') {
    return <CoachHomePage />;
  } else if (userType === 'business') {
    return <BusinessHomePage />;
  } else {
    return <div>Error: Unknown user type</div>;
  }
}

export default HomePage;
