import cx from 'clsx';
import { Title, Text, Container, Button, Overlay } from '@mantine/core';
import classes from '../styles/HeroImageBackground.module.css';
import { Link } from 'react-router-dom';

export function HeroImageBackground() {
  return (
    <div className={classes.wrapper}>
      <Overlay color="#000" opacity={0.65} zIndex={1} />

      <div className={classes.inner}>
        <Title className={classes.title}>
          Sports Now{' '}
          <Text component="span" inherit className={classes.highlight}>
            , Gain Later
          </Text>
        </Title>

        <Container size={640}>
          <Text size="lg" className={classes.description}>
          Elevate Your Game with Effortless Booking and Simplified Sports Access
          </Text>
        </Container>

        <div className={classes.controls} style={{display:'flex', gap:20}}>
          <Button component={Link} className={classes.control} variant="black" size="lg" to="/coaches">
            Find Coach
          </Button>
          <Button component={Link} className={cx(classes.control, classes.secondaryControl)} size="lg" to={"/sportcenters"}>
            Book 
          </Button>
        </div>
      </div>
    </div>
  );
}