import { Text, Container, ActionIcon, Group, rem } from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram, IconBrandLinkedin, IconBrandTiktok } from '@tabler/icons-react';
import { MantineLogo } from '@mantinex/mantine-logo';
import classes from '../styles/FooterLinks.module.css';
import React from 'react';
import SportsnowLogo from '../assets/sportsnow-white.png';
import { Link } from 'react-router-dom';


const data = [
  {
    title: 'About',
    links: [
      { label: 'Find Coach', link: '/coaches' },
      { label: 'Sport Centers', link: '/sportcenters' },
      { label: 'Contact Us', link: '/contactus' },
    ],
  },
  {
    title: 'Connect',
    links: [
      { label: 'Follow our Instagram', link: 'https://www.instagram.com/sportsnow.app/' },
      { label: 'Follow on LinkedIn', link: 'https://www.linkedin.com/company/sportsnowapp/about/' },
      { label: 'Follow our TikTok', link: 'https://www.tiktok.com/@sportsnow.app' },
    ],
  },
];

export function FooterLinks() {
  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Link
        key={index}
        className={classes.link}
        component="a"
        to={link.link}
        style={{ textDecoration: 'none' }}
        // onClick={(event) => event.preventDefault()}
      >
        {link.label}
      </Link>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        <div className={classes.logo}>
          <img src={SportsnowLogo} alt="My Logo" style={{ width: '50px', height: 'auto' }} />

          <Text size="xs" c="dimmed" className={classes.description}>
          go-to platform for seamless sports booking and play
          </Text>
        </div>
        <div className={classes.groups}>{groups}</div>
      </Container>
      <Container className={classes.afterFooter}>
        <Text c="dimmed" size="sm">
          © 2024 SportsNow .dev. All rights reserved.
        </Text>

        <Group gap={0} className={classes.social} justify="flex-end" wrap="nowrap">
        <a href="https://www.linkedin.com/company/sportsnowapp/a" target="_blank" rel="noopener noreferrer">
          <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandLinkedin style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
        </a>
        <a href="https://www.instagram.com/sportsnow.app/" target="_blank" rel="noopener noreferrer">
          <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandInstagram style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
        </a>
        <a href="https://www.tiktok.com/@sportsnow.app" target="_blank" rel="noopener noreferrer">
          <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandTiktok style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
        </a>

        </Group>
      </Container>
    </footer>
  );
}