import React, { useEffect, useState } from 'react';
import { Container, Image, Loader, Text, Card, Group, Badge, Stack } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import axios from 'axios';
import '@mantine/carousel/styles.css';
import { CoachActionsGrid } from '../components/CoachActionsGrid.tsx';
import { fetchAndSetUserData } from '../utils/auth';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BusinessActionsGrid } from '../components/BusinessActionsGrid.tsx';

// Properly extend dayjs with the UTC plugin
dayjs.extend(utc);

function CustomerHomePage() {
  const [banners, setBanners] = useState([]);
  const [coachBookingHistory, setCoachBookingHistory] = useState([]);
  const [venueBookingHistory, setVenueBookingHistory] = useState([]);
  const [loadingBanners, setLoadingBanners] = useState(true);
  const [loadingCoachBookingHistory, setLoadingCoachBookingHistory] = useState(true);
  const [loadingVenueBookingHistory, setLoadingVenueBookingHistory] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Fetch Banners
    const fetchBanners = async () => {
      try {
        setLoadingBanners(true);
        const response = await axios.post('https://apidev.sportsnow.app/api/v1/auth/banner', {}, {
          withCredentials: true
        });
        if (response.data && response.data.code === 200) {
          setBanners(response.data.data);
        } else {
          throw new Error(response.data.message || 'Failed to fetch banner data');
        }
      } catch (err) {
        console.error('Error fetching banners:', err);
        setError('Failed to fetch banners');
      } finally {
        setLoadingBanners(false);
      }
    };

    // Fetch User Data to get User ID
    const fetchUserId = async () => {
      await fetchAndSetUserData(
        (userData) => {
          if (userData) {
            setUserId(userData.id); // Set the user ID in the state
          }
        },
        null,
        (fetchError) => {
          setError(fetchError);
        }
      );
    };

    // Fetch Coach Booking History once userId is available
    const fetchCoachBookingHistory = async (accountId) => {
      try {
        setLoadingCoachBookingHistory(true);
        const response = await axios.post('https://apidev.sportsnow.app/api/v1/auth/booking-coach/history', {
          id: accountId,
        }, {
          withCredentials: true
        });
        if (response.data && response.data.code === 200) {
          setCoachBookingHistory(response.data.data);
        } else {
          throw new Error(response.data.message || 'Failed to fetch coaching history');
        }
      } catch (err) {
        console.error('Error fetching coaching history:', err);
        // setError('Failed to fetch coaching history');
      } finally {
        setLoadingCoachBookingHistory(false);
      }
    };

    // Fetch Venue Booking History once userId is available
    const fetchVenueBookingHistory = async (accountId) => {
      try {
        setLoadingVenueBookingHistory(true);
        const response = await axios.post('https://apidev.sportsnow.app/api/v1/auth/bookings/history', {
          account_id: accountId,
        }, {
          withCredentials: true
        });
        if (response.data && response.data.code === 200) {
          setVenueBookingHistory(response.data.data);
        } else {
          throw new Error(response.data.message || 'Failed to fetch venue booking history');
        }
      } catch (err) {
        console.error('Error fetching venue booking history:', err);
        // setError('Failed to fetch venue booking history');
      } finally {
        setLoadingVenueBookingHistory(false);
      }
    };

    fetchBanners();
    fetchUserId();

    if (userId) {
      fetchCoachBookingHistory(userId);
      fetchVenueBookingHistory(userId);
    }
  }, [userId]);

  return (
    <>
      {/* Banner Carousel */}
      {loadingBanners && <div>Loading banners...</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {!loadingBanners && !error && (
        <Carousel
          withIndicators
          height={500}
          slideSize="100%"
          slideGap="md"
          align="start"
          loop
          mt={20}
          breakpoints={[
            { maxWidth: 'md', slideSize: '50%' },
            { maxWidth: 'sm', slideSize: '100%' },
          ]}
        >
          {/* {banners.map((banner) => ( */}
            <Carousel.Slide >
              <a href="" target="_blank" rel="noopener noreferrer">
                <Image
                  src={`https://www.duyabilliards.com/templates/default/images/banner-pro.jpg`}
                  alt="Banner"
                  height={500}
                  fit="cover"
                />
              </a>
            </Carousel.Slide>

            <Carousel.Slide >
              <a href="" target="_blank" rel="noopener noreferrer">
                <Image
                  src={`https://scontent-cgk1-2.xx.fbcdn.net/v/t39.30808-6/465339287_8994836547240343_1571207559379294111_n.jpg?stp=dst-jpg_p180x540&_nc_cat=101&ccb=1-7&_nc_sid=833d8c&_nc_ohc=VyL97Q_VyrwQ7kNvgF2BrQ_&_nc_zt=23&_nc_ht=scontent-cgk1-2.xx&_nc_gid=ARsco-YUbmI8pL2x5NwYqog&oh=00_AYBORq78zElToFOVaVFC0ROda4jI4lUc9EBkzhNq7h-9rg&oe=67440958`}
                  alt="Banner"
                  height={500}
                  fit="cover"
                />
              </a>
            </Carousel.Slide>

            <Carousel.Slide>
              <a href="" target="_blank" rel="noopener noreferrer">
                <Image
                  src={`https://scontent-cgk2-1.xx.fbcdn.net/v/t39.30808-6/245766953_4075105409261170_1335967203923353104_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=127cfc&_nc_ohc=WTZgmnE5hn8Q7kNvgHcgp0x&_nc_zt=23&_nc_ht=scontent-cgk2-1.xx&_nc_gid=AP9ijtOTBtaURpU7I-YHx53&oh=00_AYB47wpZPgfz-r5IZ12Qokr51t9Z582BduW_376PPQ1tZQ&oe=6743FD0A`}
                  alt="Banner"
                  height={500}
                  fit="cover"
                />
              </a>
            </Carousel.Slide>
          {/* ))} */}
        </Carousel>
      )}

      <Container size="lg" py="xl">
        {/* Recommendation Grids */}
        <BusinessActionsGrid title={"Sport Centers"} />
        <CoachActionsGrid title={"Coaches"} />

        {/* Booking History Section */}
        <Text size="xl" weight={700} mt="lg" mb="sm">
          Booking History
        </Text>

        {/* Coaching Booking History Section */}
        <Text size="lg" weight={600} mt="lg" mb="sm">
          Coaching History
        </Text>
        {loadingCoachBookingHistory ? (
          <Loader>Loading coaching history...</Loader>
        ) : coachBookingHistory.length > 0 ? (
          <Stack spacing="md">
            {coachBookingHistory.map((booking) => (
              <Card key={booking.id} withBorder radius="md" padding="lg">
                <Group position="apart">
                  <Text size="lg" weight={700}>Coach ID: {booking.coach_id}</Text>
                  <Badge color={booking.status === 'booked' ? 'blue' : 'green'} variant="filled">
                    {booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
                  </Badge>
                </Group>
                <Text size="sm" color="dimmed" mt="sm">
                  Booking Date: {dayjs(booking.start_time).utc().format('MM/DD/YYYY')}
                </Text>
                <Text size="sm" mt="sm">
                  <strong>Start Time:</strong> {dayjs(booking.start_time).utc().format('MM/DD/YYYY, h:mm A')}
                </Text>
                <Text size="sm">
                  <strong>End Time:</strong> {dayjs(booking.end_time).utc().format('MM/DD/YYYY, h:mm A')}
                </Text>
                <Text size="sm" mt="sm">
                  <strong>Price:</strong> {booking.price} IDR
                </Text>
              </Card>
            ))}
          </Stack>
        ) : (
          <Text>No coaching history found.</Text>
        )}

        {/* Venue/Sports Center Booking History Section */}
        <Text size="lg" weight={600} mt="lg" mb="sm">
          Venue Booking History
        </Text>
        {loadingVenueBookingHistory ? (
          <Loader>Loading venue booking history...</Loader>
        ) : venueBookingHistory.length > 0 ? (
          <Stack spacing="md">
            {venueBookingHistory.map((booking) => (
              <Card key={booking.booking_id} withBorder radius="md" padding="lg">
                <Group position="apart">
                  <Text size="lg" weight={700}>Booking ID: {booking.booking_id}</Text>
                  <Badge color="blue" variant="filled">
                    {booking.schedule_type === 'hourly' ? 'Hourly' : 'Session'}
                  </Badge>
                </Group>
                <Text size="sm" color="dimmed" mt="sm">
                  Booking Date: {dayjs(booking.start_hour).utc().format('MM/DD/YYYY')}
                </Text>
                <Text size="sm" mt="sm">
                  <strong>Start Time:</strong> {dayjs(booking.start_hour).utc().format('MM/DD/YYYY, h:mm A')}
                </Text>
                <Text size="sm">
                  <strong>End Time:</strong> {dayjs(booking.end_hour).utc().format('MM/DD/YYYY, h:mm A')}
                </Text>
                <Text size="sm" mt="sm">
                   <strong>Price:</strong> {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(booking.price)}
                </Text>
              </Card>
            ))}
          </Stack>
        ) : (
          <Text>No venue booking history found.</Text>
        )}
      </Container>
    </>
  );
}

export default CustomerHomePage;
