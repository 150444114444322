import React, { useEffect, useState } from 'react';
import {
  Container,
  Card,
  Text,
  Group,
  Divider,
  Button,
  Modal,
  TextInput,
  NumberInput,
  Stack,
  Badge,
} from '@mantine/core';
import axios from 'axios';
import { getDecodedPayload, getAuthTokenFromCookies } from '../utils/jwt';
import { IconCheck, IconX, IconClock, IconCalendar, IconPlus } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function CoachHomePage() {
  const [coachStatus, setCoachStatus] = useState(null);
  const [coachSchedule, setCoachSchedule] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [loadingSchedule, setLoadingSchedule] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [formValues, setFormValues] = useState({
    price: '',
    date: dayjs(),
    start_time: '',
    end_time: '',
  });

  const handleInputChange = (field, value) => {
    setFormValues({ ...formValues, [field]: value });
  };

  useEffect(() => {
    const token = getAuthTokenFromCookies();

    if (!token) {
      setError('User is not authenticated.');
      return;
    }

    const decodedPayload = getDecodedPayload(token);
    if (!decodedPayload || !decodedPayload.sub) {
      setError('Invalid token or user ID missing.');
      return;
    }

    const userId = decodedPayload.sub;

    fetchCoachStatus(userId);
    fetchCoachSchedule(userId);
  }, []);

  const fetchCoachStatus = async (userId) => {
    try {
      setLoadingStatus(true);
      const response = await axios.post('https://apidev.sportsnow.app/api/v1/auth/coach/status', { id: userId }, { withCredentials: true });
      if (response.data && response.data.code === 200) {
        setCoachStatus(response.data.data);
      } else {
        throw new Error(response.data.message || 'Failed to fetch coach status');
      }
    } catch (err) {
      console.error('Error fetching coach status:', err);
      setError('Failed to fetch coach status');
    } finally {
      setLoadingStatus(false);
    }
  };

  const fetchCoachSchedule = async (userId) => {
    try {
      setLoadingSchedule(true);
      const response = await axios.post('https://apidev.sportsnow.app/api/v1/auth/coach/schedule', { id: userId }, { withCredentials: true });
      if (response.data && response.data.code === 200) {
        setCoachSchedule(response.data.data);
      } else {
        throw new Error(response.data.message || 'Failed to fetch coach schedule');
      }
    } catch (err) {
      setError('Failed to fetch coach schedule');
      console.error('Error fetching coach schedule:', err);
    } finally {
      setLoadingSchedule(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const token = getAuthTokenFromCookies();
      const decodedPayload = getDecodedPayload(token);
      const coachId = decodedPayload.sub;

      const selectedDate = formValues.date.format('YYYY-MM-DD');
      const startDateTime = `${selectedDate}T${formValues.start_time}:00Z`;
      const endDateTime = `${selectedDate}T${formValues.end_time}:00Z`;

      const response = await axios.post(
        'https://apidev.sportsnow.app/api/v1/auth/coach/schedule/add',
        {
          coach_id: coachId,
          price: formValues.price,
          start_time: startDateTime,
          end_time: endDateTime,
        },
        { withCredentials: true }
      );

      if (response.data && response.data.code === 200) {
        showNotification({
          title: 'Success',
          message: 'Schedule added successfully!',
          color: 'green',
          icon: <IconCheck />,
        });
        setModalOpen(false);
        setFormValues({ price: '', date: dayjs(), start_time: '', end_time: '' });
        fetchCoachSchedule(coachId);
      } else {
        throw new Error(response.data.message || 'Failed to add schedule');
      }
    } catch (err) {
      console.error('Error adding schedule:', err);
      showNotification({
        title: 'Failed',
        message: 'Failed to add schedule',
        color: 'red',
        icon: <IconX />,
      });
    } finally {
      setLoading(false);
    }
  };

  const today = dayjs();
  const nextMonth = today.add(1, 'month');

  return (
    <Container size="lg" py="xl">
      {/* Add Schedule Button and Modal */}
     <Button onClick={() => setModalOpen(true)} mt="xl" mb={'xl'}>
        Add Schedule
      </Button>
      <Modal opened={modalOpen} onClose={() => setModalOpen(false)} title="Add Schedule" ml>
        <Stack spacing="md">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={formValues.date}
              onChange={(newValue) => handleInputChange('date', newValue)}
              minDate={today}
              maxDate={nextMonth}
              sx={{
                  '& .MuiTypography-root': { color: 'white' },
                  '& .Mui-selected': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                  '& .MuiButtonBase-root': { color: 'white' },
                }}
            />
          </LocalizationProvider>
          <NumberInput
            label="Price"
            value={formValues.price}
            onChange={(value) => handleInputChange('price', value)}
            required
          />
          <TextInput
            label="Start Time (HH:mm)"
            value={formValues.start_time}
            placeholder="e.g. 14:00"
            onChange={(event) => handleInputChange('start_time', event.currentTarget.value)}
            required
          />
          <TextInput
            label="End Time (HH:mm)"
            value={formValues.end_time}
            placeholder="e.g. 16:00"
            onChange={(event) => handleInputChange('end_time', event.currentTarget.value)}
            required
          />
          <Button onClick={handleSubmit} fullWidth loading={loading}>
            Submit
          </Button>
        </Stack>
      </Modal>

      

      {/* Coach Status */}
      <Card withBorder radius="md" shadow="md" padding="xl" mb="xl">
        <Text size="xl" weight={700} mb="md">
          Coach Status
        </Text>
        <Divider mb="lg" />
        {loadingStatus ? (
          <Text align="center">Loading coach status...</Text>
        ) : (
          <Group position="apart" grow>
            <Badge size="lg" color="blue" leftSection={<IconClock size={16} />}>
              Today’s Sessions: {coachStatus?.today_schedule}
            </Badge>
            <Badge size="lg" color="orange" leftSection={<IconCalendar size={16} />}>
              Pending Sessions: {coachStatus?.undone_schedule}
            </Badge>
            <Badge size="lg" color="green" leftSection={<IconCheck size={16} />}>
              Completed Sessions: {coachStatus?.done_schedule}
            </Badge>
          </Group>
        )}
      </Card>

      {/* Coach Schedule */}
      <Card withBorder radius="md" padding="xl">
        <Text size="xl" weight={500} mb="sm">
          Schedule
        </Text>
        <Divider mb="sm" />
        {loadingSchedule ? (
          <Text align="center">Loading schedule...</Text>
        ) : coachSchedule.length === 0 ? (
          <Text align="center" mt={20}>
            No schedule found.
          </Text>
        ) : (
          coachSchedule.map((schedule) => (
            <Card key={schedule.id} withBorder padding="lg" radius="md" mt="sm" shadow="sm">
              <Group position="apart">
                <Text weight={500}>Session</Text>
                <Badge color={schedule.status === 'available' ? 'blue' : 'red'}>{schedule.status}</Badge>
              </Group>
              <Divider my="sm" />
              <Text>Start: {new Date(schedule.start_time).toLocaleString()}</Text>
              <Text>End: {new Date(schedule.end_time).toLocaleString()}</Text>
              <Text>Price: {schedule.price} IDR</Text>
            </Card>
          ))
        )}
      </Card>
    </Container>
  );
}

export default CoachHomePage;
