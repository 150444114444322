import React from 'react';
import '@mantine/core/styles.css';

import { StepsForm } from '../components/StepsForm';



function AvailablePage() {
  return (
      <StepsForm></StepsForm>
  );
}

export default AvailablePage;
