import { useEffect, useState } from 'react';
import { Stepper, Button, Group, TextInput, PasswordInput, Code, Center, rem, Text, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft } from '@tabler/icons-react';
import { useTimer } from '../context/TimerContext';

export function StepsForm() {
  let [active, setActive] = useState(0);
  const { timeLeft, setTimeLeft, clearBookingData } = useTimer();
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      type: '',
      method: '',
    },
    validate: (values) => {
      if (active === 0) {
        return { type: values.type === '' ? 'Please select a type' : null };
      }
      if (active === 1) {
        return { method: values.method === '' ? 'Please select a method' : null };
      }
      return {};
    },
  });

  useEffect(() => {
    const bookingData = JSON.parse(localStorage.getItem('bookingData'));
    if (bookingData) {
      setActive(2);
      setTimeLeft(bookingData.timeLeft); // Set from local storage
    }
  }, [setTimeLeft]);

useEffect(() => {
    let interval;

    if (active === 2) {
        const bookingData = JSON.parse(localStorage.getItem('bookingData'));
        if (bookingData && bookingData.timeLeft) {
            setTimeLeft(bookingData.timeLeft);
        } else {
            setTimeLeft(900); // Start from 15 seconds if not set
            localStorage.setItem('bookingData', JSON.stringify({ timeLeft: 15, paid: true }));
        }

        interval = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime > 0) {
                    const newTime = prevTime - 1;
                    localStorage.setItem('bookingData', JSON.stringify({ timeLeft: newTime, paid: true }));
                    return newTime;
                } else {
                    clearInterval(interval);
                    return 0; // Prevent negative time
                }
            });
        }, 1000);
    }

    return () => {
        clearInterval(interval); // Clear the interval on cleanup
    };
}, [active, setTimeLeft]);


  const formatTimeLeft = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const nextStep = () => {
    const errors = form.validate();
    if (!errors.hasErrors) {
      setActive((current) => (current < 3 ? current + 1 : current));
    }
  };

  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const resetBookingData = () => {
    clearBookingData();
    setActive(0); // Go back to the first step
    localStorage.removeItem('bookingData'); // Clear local storage
    setTimeLeft(0); // Reset time left
  };

  return (
    <>
      <Group mt={20} ml={100}>
        <a href="/tables" style={{ textDecoration: 'none', color: 'inherit', display: 'inline-flex', alignItems: 'center', marginBottom: 20, width: 'fit-content' }}>
          <IconArrowLeft style={{ width: rem(30), height: rem(30), display: 'inline' }} stroke={1.5} />
          <Text fz="md" fw={600} c="dimmed" style={{ display: 'inline' }}>Back</Text>
        </a>
      </Group>
      <div style={{ width: '40%', margin: '20px auto', alignItems: 'center', marginBottom: '200px' }}  p={20}>
        <Stepper active={active}>
          <Stepper.Step label="First step" description="Table type">
            <Select
              label="Type"
              mt={'md'}
              placeholder="Pick value"
              data={['Basic', 'Premium', 'Professional']}
              {...form.getInputProps('type')}
            />
          </Stepper.Step>
          <Stepper.Step label="Second step" description="Payment">
            <Group justify='space-between'>
              <Text fz={'xl'}>Price</Text>
              <Text fz={'md'}>Rp1.000 / minute</Text>
            </Group>
            <Group justify='space-between'>
              <Text fz={'xl'}>Booking Price</Text>
              <Text fz={30} fw={'bolder'}>Rp50.000</Text>
            </Group>
            <Text fz={'md'} color="dimmed" mt={10}>
              If user hasn't clocked in 15 minutes after payment, then the fund will be refunded as SportsNowCoin
            </Text>
            <Select
              label="Method"
              placeholder="Select a method"
              data={['Credit Card', 'Bank Transfer', 'Digital Wallet']}
              style={{ marginTop: '10px' }}
              {...form.getInputProps('method')}
            />
          </Stepper.Step>
          <Stepper.Completed>
            <Text fz={'xl'} color='green'>Paid!</Text>
            <Text fz={100} mt="xl" fw={'bolder'}>
              {formatTimeLeft(timeLeft)}
            </Text>
            {/* Clock In Button */}
            <Button mt="xl" disabled={timeLeft === 0}>Clock In</Button>
            <Button mt="xl" onClick={resetBookingData} style={{ display: 'block' }}>.</Button>
          </Stepper.Completed>
        </Stepper>

        <Group pos={Center} mt={'xl'}>
          {active !== 0 && active !== 2 && (
            <Button variant="default" onClick={prevStep}>Back</Button>
          )}
          {active !== 2 && <Button onClick={nextStep}>Next step</Button>}
        </Group>
      </div>
    </>
  );
}
