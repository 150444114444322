import React from 'react';
import '@mantine/core/styles.css';

import { Flex, Grid, SimpleGrid } from '@mantine/core';
import { RegistrationFormBusiness } from '../components/RegistrationFormBusiness.tsx';

function RegisterPageBusiness() {
  return (
      <SimpleGrid 
      cols={{ base: 1, sm: 1, lg: 1 }}  
      style={{margin: 50, justifyContent: 'center', display:'flex' }}
      >
        <RegistrationFormBusiness title="Personal" w={'100%'} margin={'50px'}/>
      </SimpleGrid>
  );
}

export default RegisterPageBusiness;
