import { Card, Image, Text, Group, Button } from '@mantine/core';
import classes from '../styles/FeaturesCard.module.css';
import { Link } from 'react-router-dom';

export function CoachFeaturesCard({
  id,
  name,
  profilePicture,
  description,
  price,
  onBook
}) {

  return (
    <Card withBorder radius="md" className={classes.card}>
      <Card.Section className={classes.imageSection}>
        <Image
          src={profilePicture ? `https://apidev.sportsnow.app/uploads/coach/${profilePicture}` : '/path/to/default-avatar.png'}
          alt={name}
        />
      </Card.Section>

      <Group justify="space-between" mt="md">
        <Text fw={500}>{name}</Text>
        <Text fz="lg" fw={700} color="" ml={4} style={{ fontWeight: 'bold' }}>
          {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(price)}
        </Text>
      </Group>

      <Text fz="sm" c="dimmed" mt="md">
        {description.length > 50 ? `${description.substring(0, 50)}...` : description}
      </Text>

      <Group justify="flex-end" mt="md">
        <Button
          radius="xl"
          style={{ flex: 1 }}
          onClick={onBook}
        >
          Book
        </Button>
      </Group>
    </Card>
  );
}
