import React from 'react';
import '@mantine/core/styles.css';

import { HeroImageBackground } from '../components/HeroImageBackground.js';
import { FeaturesCards } from '../components/FeaturesCards.js';
import { CoachActionsGrid } from '../components/CoachActionsGrid.tsx';
import { BusinessActionsGrid } from '../components/BusinessActionsGrid.tsx';

function LandingPage() {
    return (
        <>
          <HeroImageBackground />
          <FeaturesCards>
            <BusinessActionsGrid title={"Sport Centers"} />
            <CoachActionsGrid title={"Coaches"} />
          </FeaturesCards>
        </>
      );
}

export default LandingPage;
