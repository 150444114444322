import {
    Card,
    Text,
    SimpleGrid,
    UnstyledButton,
    Anchor,
    Group,
    useMantineTheme,
  } from '@mantine/core';
  import {
    IconCreditCard,
    IconBuildingBank,
    IconRepeat,
    IconReceiptRefund,
    IconReceipt,
    IconReceiptTax,
    IconReport,
    IconCashBanknote,
    IconCoin,
    IconStar
  } from '@tabler/icons-react';
  import classes from '../styles/ActionsGrid.module.css';
  import { BusinessFeaturesCard} from './BusinessFeaturesCard'; // Import FeaturesCard
  import { mockdata } from '../datas/mockdata';
import React, { useEffect, useState } from 'react';
import { CoachFeaturesCard } from './CoachFeaturesCard';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
  
export function CoachActionsGrid({title}) {
  const navigate = useNavigate();

  const theme = useMantineTheme();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post('https://apidev.sportsnow.app/api/v1/coaches', { page: 1 });
        if (response.data && response.data.code === 200) {
          setData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const limitedItems = data.slice(0, 3);

  const handleCardClick = (coachId) => {
    navigate(`/coach/${coachId}`); 
  };

  const items = limitedItems.map((item) => (
      <CoachFeaturesCard
        key={item.id}
        id={item.id}
        name={item.name}
        email={item.email}
        phoneNumber={item.phone_number}
        profilePicture={item.profile_picture}
        description={item.description}
        price={item.price}
        onBook={() => handleCardClick(item.id)}
      />
  ));

  return (
    <Card withBorder radius="md" className={classes.card} mb={50}>
      <Group justify="space-between">
        <Text className={classes.title}>{title}</Text>
        <Anchor size="xs" c="dimmed" style={{ lineHeight: 1 }} href={`/${title.toLowerCase().replace(/\s+/g, '')}`}>
          + {data.length} other {title.toLowerCase()}
        </Anchor>
      </Group>
      {loading ? (
        <Text align="center" mt="md">Loading...</Text>
      ) : (
        <SimpleGrid 
          cols={{ base: 1, sm: 2, lg: 3 }} 
          mt="md" 
        >
          {items}
        </SimpleGrid>
      )}
    </Card>
  );
}