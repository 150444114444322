import { Card, Text, Group, Badge, Button, Image } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import classes from '../styles/FeaturesCard.module.css';

export function VenueFeaturesCard({ id, name, status, type, scheduleType, price, profilePicture }) {
  const navigate = useNavigate();

  const handleBookClick = () => {
    navigate(`/venues/${id}/book`, {
      state: {
        venue: {
          id,
          name,
          status,
          type,
          scheduleType,
          price,
          profilePicture,
        },
      },
    });
  };

  return (
    <Card withBorder radius="md" className={classes.card}>
      <Card.Section className={classes.imageSection}>
        <Image
          src={profilePicture ? `https://apidev.sportsnow.app/uploads/venue/${profilePicture}` : '/path/to/default-avatar.png'}
          alt={`Venue ${name}`}
          height={160}
          fit="cover"
        />
      </Card.Section>

      <Group justify="space-between" mt="md">
        <Text fw={500}>{name}</Text>
        <Badge color={status === 'available' ? 'green' : 'red'}>
          {status === 'available' ? 'Available' : 'Not Available'}
        </Badge>
      </Group>

      <Group mt="xs" spacing="xs">
        <Text size="xs" color="dimmed">Type: {type.charAt(0).toUpperCase() + type.slice(1)}</Text>
        <Text size="xs" color="dimmed">Schedule: {scheduleType === 'hourly' ? "Hourly" : "Session"}</Text>
      </Group>

      <Group justify="space-between" mt="md">
        <Text fz="md" fw={700} color="">
          {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(price)} {scheduleType === 'hourly' ? "/hour" : "/minute"}
        </Text>
        <Button onClick={handleBookClick} radius="xl" style={{ flex: 0.5 }}>
          Book
        </Button>
      </Group>
    </Card>
  );
}
