import { useForm } from '@mantine/form';
import {
  TextInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Stack,
  NumberInput,
  FileInput,
  Textarea,
  ActionIcon,
  rem,
} from '@mantine/core';
import axios from 'axios';
import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TimeInput } from '@mantine/dates';
import { IconClock } from '@tabler/icons-react';

interface RegistrationFormBusinessProps extends PaperProps {
  title: string;
}

export function RegistrationFormBusiness({ title, ...props }: RegistrationFormBusinessProps) {
  const location = useLocation(); // Access the location to get the state
  const [isLoading, setIsLoading] = useState(false);
  const [profilePictureURL, setProfilePictureURL] = useState(''); // To store the URL after file upload
  const navigate = useNavigate();


  // Registration form validation with email pre-filled from OTP verification
  const form = useForm({
    initialValues: {
      email: location.state?.email || '', // Pre-fill email from state, or default to empty
      name: '',
      phoneNumber: '',
      address: '',
      description: '',
      openHour: '', // Store time as a string
      closeHour: '', // Store time as a string
      longitude: 0,
      latitude: 0,
      profilePicture: null as File | null, // Specify type File | null
    },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
      phoneNumber: (val) => (val ? null : 'Phone number is required'),
      profilePicture: (val) => (val ? null : 'Profile picture is required'),
      name: (val) => (val ? null : 'Name is required'),
      address: (val) => (val ? null : 'Address is required'),
      description: (val) => {
        if (val.length < 10) {
          return 'Description must be at least 10 characters';
        } else if (val.length > 100) {
          return 'Description must be less than 100 characters';
        }
        return null;
      },
      openHour: (val) => (val ? null : 'Open hour is required'),
      closeHour: (val) => (val ? null : 'Close hour is required'),
      longitude: (val) => (val ? null : 'Longitude is required'),
      latitude: (val) => (val ? null : 'Latitude is required'),
    },
  });

  // Function to convert "hh:mm AM/PM" to "HH:mm" (24-hour format)
  const convertTo24HourFormat = (time: string) => {
    const [timePart, modifier] = time.split(' ');

    let [hours, minutes] = timePart.split(':');
    let hoursInt = parseInt(hours);

    if (modifier === 'PM' && hoursInt < 12) {
      hoursInt += 12;
    }
    if (modifier === 'AM' && hoursInt === 12) {
      hoursInt = 0;
    }

    return `${hoursInt.toString().padStart(2, '0')}:${minutes}`;
  };

  // Handles the profile registration form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!form.validate().hasErrors) {
      setIsLoading(true);

      try {
        // Upload Profile Picture First
        const { profilePicture } = form.values;
        if (!profilePicture) {
          alert('Please select a profile picture first!');
          return;
        }

        if (!form.values.profilePicture) {
          form.setFieldError('profilePicture', 'Profile picture is required');
          return;
        }
  
        if (form.values.profilePicture.size > 2.5 * 1024 * 1024) {
          form.setFieldError('profilePicture', 'File size should not exceed 2.5MB');
          return;
        }      

        const formData = new FormData();
        formData.append('profilePicture', profilePicture);

        const uploadResponse = await axios.post(
          'https://apidev.sportsnow.app/api/v1/auth/upload/business/image',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          }
        );

        // Set the URL for the profile picture after uploading it
        const uploadedImageUrl = uploadResponse.data.url;
        setProfilePictureURL(uploadedImageUrl);

        const convertTo24HourFormat = (time: string) => {
          const [timePart, modifier] = time.split(' ');
        
          let [hours, minutes] = timePart.split(':');
          let hoursInt = parseInt(hours);
        
          if (modifier === 'PM' && hoursInt < 12) {
            hoursInt += 12;
          }
          if (modifier === 'AM' && hoursInt === 12) {
            hoursInt = 0;
          }
        
          return `${hoursInt.toString().padStart(2, '0')}:${minutes}`;
        };

        const convertToISOTime = (time: string) => {
          const [timePart, modifier] = time.split(' ');
        
          let [hours, minutes] = timePart.split(':');
          let hoursInt = parseInt(hours);
        
          if (modifier === 'PM' && hoursInt < 12) {
            hoursInt += 12;
          }
          if (modifier === 'AM' && hoursInt === 12) {
            hoursInt = 0;
          }
        
          // Return ISO format with a placeholder date and UTC timezone
          return `1970-01-01T${hoursInt.toString().padStart(2, '0')}:${minutes}:00Z`;
        };
        
        // Convert time inputs to 24-hour format before submission
        const openHour24 = convertToISOTime(form.values.openHour);
        const closeHour24 = convertToISOTime(form.values.closeHour);


        // Proceed with Business Registration
        const registrationResponse = await axios.post(
          'https://apidev.sportsnow.app/api/v1/auth/business/sign-up/submit',
          {
            email: form.values.email,
            name: form.values.name,
            phone_number: form.values.phoneNumber.toString().replace(/[^0-9]/g, ''), // Ensure phone number is numeric as a string
            address: form.values.address,
            description: form.values.description,
            open_hour: openHour24,
            close_hour: closeHour24,
            longitude: form.values.longitude,
            latitude: form.values.latitude,
            profile_picture: uploadedImageUrl, 
          },
          {
            withCredentials: true,
          }
        );

        console.log(registrationResponse.status);
        alert('Registration successful!');
        navigate('/profile'); 
        window.location.reload();

      } catch (error) {
        console.error('Registration failed:', error);
        alert('Registration failed. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div
      style={{
        flex: '1 1 55%',
        maxWidth: '600px',
        width: '100%',
      }}
    >
      <Paper
        radius="md"
        p="xl"
        w={'100%'}
        h={'100%'}
        style={title === 'Business' ? { background: '#1871c3' } : {}}
        withBorder={title !== 'Business'}
        {...props}
      >
        <div style={{ textAlign: 'center' }}>
          <Text fz={30} fw={500} mb={30}>
            Register Business Account
          </Text>
        </div>

        <form onSubmit={handleSubmit}>
          <Stack>
            <TextInput
              label="Email"
              placeholder="hello@sportsnow.app"
              value={form.values.email}
              onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
              error={form.errors.email && 'Invalid email'}
              radius="md"
              disabled 
            />
            <TextInput
              label="Name"
              placeholder="Enter your name"
              value={form.values.name}
              onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
              error={form.errors.name && 'Name is required'}
              radius="md"
            />
            <TextInput
              label="Address"
              placeholder="Enter your address"
              value={form.values.address}
              onChange={(event) => form.setFieldValue('address', event.currentTarget.value)}
              error={form.errors.address && 'Address is required'}
              radius="md"
            />
            <Textarea
              label="Description"
              placeholder="Enter a brief description of your business"
              value={form.values.description}
              onChange={(event) => form.setFieldValue('description', event.currentTarget.value)}
              error={form.errors.description}
              radius="md"
            />
            <TextInput
              type="time"
              label="Open Hour"
              value={form.values.openHour}
              onChange={(event) => form.setFieldValue('openHour', event.currentTarget.value)}
              error={form.errors.openHour && 'Open hour is required'}
              radius="md"
            />
            <TextInput
              type="time"
              label="Close Hour"
              value={form.values.closeHour}
              onChange={(event) => form.setFieldValue('closeHour', event.currentTarget.value)}
              error={form.errors.closeHour && 'Close hour is required'}
              radius="md"
            />
            <NumberInput
              label="Phone Number"
              placeholder="Enter your phone number"
              value={form.values.phoneNumber}
              onChange={(value) => form.setFieldValue('phoneNumber', value || '')}
              error={form.errors.phoneNumber && 'Phone number is required'}
              radius="md"
              hideControls 
            />
            <NumberInput
              label="Longitude"
              placeholder="Enter the longitude of your business location"
              value={form.values.longitude}
              onChange={(value) => form.setFieldValue('longitude', value || 0)}
              error={form.errors.longitude && 'Longitude is required'}
              radius="md"
            />
            <NumberInput
              label="Latitude"
              placeholder="Enter the latitude of your business location"
              value={form.values.latitude}
              onChange={(value) => form.setFieldValue('latitude', value || 0)}
              error={form.errors.latitude && 'Latitude is required'}
              radius="md"
            />
            <FileInput
              label="Profile Picture"
              placeholder="Upload your profile picture"
              accept="image/*"
              value={form.values.profilePicture}
              onChange={(file: File | null) => {
                if (!file) {
                  form.setFieldError('profilePicture', 'Profile picture is required');
                  return;
                }
                if (file && file.size > 2.5 * 1024 * 1024) { // 2.5MB limit
                  form.setFieldError('profilePicture', 'File size should not exceed 2.5MB');
                  return;
                }
                form.setFieldError('profilePicture', null); // Clear error if file is valid
                form.setFieldValue('profilePicture', file);
              }}
              error={form.errors.profilePicture} // Show error below the input field
              radius="md"
            />
          </Stack>

          <Group justify="space-between" mt="xl">
            <Button type="submit" radius="xl" loading={isLoading}>
              {isLoading ? 'Loading...' : 'Register'}
            </Button>
          </Group>
        </form>
      </Paper>
    </div>
  );
}
