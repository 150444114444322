import { Card, Image, Text, Group, Button, Flex, ThemeIcon } from '@mantine/core';
import { IconMapPin, IconClock } from '@tabler/icons-react';
import classes from '../styles/FeaturesCard.module.css';

const formatTimeWithoutConversion = (isoTime) => {
  const [hours, minutes] = new Date(isoTime).toISOString().substr(11, 5).split(':');
  return `${hours}:${minutes}`;
};

export function BusinessFeaturesCard({
  title,
  email,
  phoneNumber,
  address,
  openHour,
  closeHour,
  description,
  longitude,
  latitude,
  isOpen,
  profilePicture,
  onBook
}) {
  const isBookingDisabled = !isOpen;

  return (
    <Card withBorder radius="md" className={classes.card}>
      {/* Image Section */}
      <Card.Section className={classes.imageSection}>
        <Image
          src={profilePicture ? `https://apidev.sportsnow.app/uploads/business/${profilePicture}` : '/path/to/default-avatar.png'}
          alt={title}
        />
      </Card.Section>
      

      {/* Title and Address */}
      <Group mt="md" style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <Text fw={500}>{title}</Text>
        <Group position="apart" mt={-10}>
          <Text fz="sm" c="dimmed">
          {description.length > 83 ? `${description.substring(0, 83)}...` : description}

          </Text>
        </Group>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '0px' }}>
          <IconMapPin size={16}/>
          <Text fz="xs" c="dimmed" ml={10}>
            {address.length > 45 ? `${address.substring(0, 45)}...` : address}
          </Text>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '0px' }}>
          <IconClock size={16} />
          <Text fz="xs" c="dimmed" ml={10}>
            {formatTimeWithoutConversion(openHour)} - {formatTimeWithoutConversion(closeHour)}
          </Text>
        </div>
      </Group>

      {/* Description and Booking Button */}
      <Card.Section className={classes.section}>
        <Button
          radius="xl"
          mt="md"
          style={{ width: '100%' }}
          onClick={onBook}
          disabled={isBookingDisabled} // Disable button if the venue is closed
        >
          {isBookingDisabled ? 'Closed' : 'Book'}
        </Button>
      </Card.Section>
    </Card>
  );
}
