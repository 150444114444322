import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchAndSetUserData } from '../utils/auth';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        setLoading(true);
        await fetchAndSetUserData(null, setUser, setError); // Set only user here
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setError(error.message || 'Failed to fetch user data');
      } finally {
        setLoading(false);
      }
    };

    loadUserData();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
