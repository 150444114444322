import { createContext, useContext, useState, useEffect } from 'react';

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const bookingData = JSON.parse(localStorage.getItem('bookingData'));
    if (bookingData) {
      setTimeLeft(bookingData.timeLeft);
      setIsActive(true);
    }
  }, []);

  useEffect(() => {
    let interval;
    if (isActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prev) => {
          const newTime = prev - 1;
          localStorage.setItem('bookingData', JSON.stringify({ timeLeft: newTime, paid: true }));
          if (newTime <= 0) {
            clearInterval(interval);
            return 0; // Prevent negative time
          }
          return newTime;
        });
      }, 1000);
    }
    
    return () => clearInterval(interval);
  }, [isActive, timeLeft]);

  const resetTimer = () => {
    setTimeLeft(15 * 60); // Reset to 15 minutes in seconds
    localStorage.setItem('bookingData', JSON.stringify({ timeLeft: 15 * 60, paid: false }));
  };

  const clearBookingData = () => {
    setTimeLeft(0);
    setIsActive(false);
    localStorage.removeItem('bookingData');
  };

  return (
    <TimerContext.Provider value={{ timeLeft, setTimeLeft, resetTimer, clearBookingData }}>
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => useContext(TimerContext);
