import React from 'react';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@mantine/core/styles.css';

import { HeaderMegaMenu } from '../components/HeaderMegaMenu';
import { FooterLinks } from '../components/FooterLinks';

import SportCentersPage from './SportCentersPage';
import LandingPage from './LandingPage';
import FindCoachPage from './FindCoachPage';
import TablesPage from './TablesPage';
import AvailablePage from './AvailablePage';
import BookCoachPage from './BookCoachPage';
import CoachHomePage from './CoachHomePage';
import { TimerProvider } from '../context/TimerContext';
import ContactUsPage from './ContactUsPage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import AuthRoute from '../components/AuthRoute';
import ProfilePage from './ProfilePage';
import LoginBusinessPage from './LoginBusinessPage';
import LoginCoachPage from './LoginCoachPage';
import RegisterPageCoach from './RegisterPageCoach';
import RegisterPageBusiness from './RegisterPageBusiness';
import HomePage from './HomePage';
import NoAuthRoute from '../components/NoAuthRoute';
import NotFoundPage from './NotFoundPage';
import BookVenuePage from './BookVenuePage';

import '@mantine/notifications/styles.css';
import { Notifications } from '@mantine/notifications';
import { UserProvider } from '../context/UserContext';

function App() {
  return (
    <TimerProvider>
      <UserProvider>
        <MantineProvider
          defaultColorScheme="dark"
          withGlobalStyles
          withNormalizeCSS
        >
          <Notifications />
          <Router>
            <div style={{ padding: '0px' }}>
              <HeaderMegaMenu />

              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/sportcenters" element={<SportCentersPage />} />
                <Route path="/coaches" element={<FindCoachPage />} /> {/* New Route for finding coaches */}
                <Route 
                  path="/tables" 
                  element={
                    <AuthRoute>
                      <TablesPage />
                    </AuthRoute>
                  }
                />
                <Route path="/venues/:venueId/book" element={<BookVenuePage />} />
                <Route path="/available" element={<AvailablePage />} />
                <Route 
                  path="/bookcoach" 
                  element={
                    <AuthRoute>
                      <BookCoachPage />
                    </AuthRoute>
                  }
                />
                <Route path="/contactus" element={<ContactUsPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                
                <Route
                  path="/login/customer"
                  element={
                    <NoAuthRoute>
                      <LoginPage />
                    </NoAuthRoute>
                  }
                />
                <Route
                  path="/login/business"
                  element={
                    <NoAuthRoute>
                      <LoginBusinessPage />
                    </NoAuthRoute>
                  }
                />
                <Route
                  path="/login/coach"
                  element={
                    <NoAuthRoute>
                      <LoginCoachPage />
                    </NoAuthRoute>
                  }
                />
                <Route path="/register/customer" element={<RegisterPage />} />
                <Route path="/register/coach" element={<RegisterPageCoach />} />
                <Route path="/register/business" element={<RegisterPageBusiness />} />
                
                <Route
                  path="/home"
                  element={
                    <AuthRoute>
                      <HomePage />
                    </AuthRoute>
                  }
                />
                
                <Route
                  path="/coach/home"
                  element={
                    <AuthRoute>
                      <CoachHomePage />
                    </AuthRoute>
                  }
                />

                <Route
                  path="/coach/:coachId"
                  element={
                    <AuthRoute>
                      <BookCoachPage />
                    </AuthRoute>
                  }
                />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>

              <FooterLinks />
            </div>
          </Router>
        </MantineProvider>
      </UserProvider>
    </TimerProvider>
  );
}

export default App;
