import {
  Card,
  Text,
  SimpleGrid,
  Group,
  TextInput,
  useMantineTheme,
  rem
} from '@mantine/core';
import { IconSearch, IconArrowLeft } from '@tabler/icons-react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { BusinessFeaturesCard } from './BusinessFeaturesCard';
import { useNavigate } from 'react-router-dom';
import classes from '../styles/CatalogueGrid.module.css';

export function CatalogueGrid({ title }) {
  const theme = useMantineTheme();
  const navigate = useNavigate(); // Initialize useNavigate for navigation
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post('https://apidev.sportsnow.app/api/v1/sports-center', { page: 1 });
        if (response.data && response.data.code === 200) {
          setData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleBookClick = (centerId, centerName) => {
    navigate(`/tables`, { state: { centerId, centerName } });
  };

  const filteredItems = data.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  

  const items = filteredItems.map((item) => (
    <BusinessFeaturesCard
      key={item.id}
      title={item.name}
      email={item.email}
      phoneNumber={item.phone_number}
      address={item.address}
      openHour={item.open_hour}
      closeHour={item.close_hour}
      description={item.description}
      longitude={item.longitude}
      latitude={item.latitude}
      isOpen={item.is_open}
      profilePicture={item.profile_picture}
      onBook={() => handleBookClick(item.id, item.name)}
    />
  ));

  return (
    <div style={{ padding: '0px' }}>
      <Card withBorder radius="md" className={classes.card} mb={50} padding={50}>
        <Text className={classes.title}>{title}</Text>
        <Group justify='space-between'>
          <Text className={classes.subtitle}>{filteredItems.length} {title} found</Text>
          <Group justify='start'>
            <TextInput
              placeholder={`Search ${title}`}
              leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
            />
          </Group>
        </Group>
        {loading ? (
          <Text align="center" mt="md">Loading...</Text>
        ) : filteredItems.length > 0 ? (
          <SimpleGrid cols={{ base: 1, sm: 3, lg: 4 }} mt="md">
            {items}
          </SimpleGrid>
        ) : (
          <Text align="center" mt="md" style={{ fontSize: '30px' }}>
            No {title} found
          </Text>
        )}
      </Card>
    </div>
  );
}