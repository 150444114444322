import React from 'react';
import '@mantine/core/styles.css';

import { GetInTouchSimple } from '../components/GetInTouchSimple';
import { Flex, Group } from '@mantine/core';

function ContactUsPage() {
  return (
    <Group justify='center' style={{display:'flex', justifyContent:'center'}}>
      <GetInTouchSimple />
    </Group>
  );
}

export default ContactUsPage;
